export default{
    logintoken:"",//登录时候返回的token
    userinfrom:{},//登录时候返回的详细信息
    schedulevalue: '',
    // homeurl: "https://test-link.lighos.com",  // 测试
    homeurl:"https://link.qingtv.com",//正式
    themecolor: window.sessionStorage.getItem('themecolor') || 'rgba(0, 204, 176, 0.8)',
    themecolors: window.sessionStorage.getItem('themecolors') || '#00ccb0',
    techSupporTcolors: window.sessionStorage.getItem('techSupporTcolors') || '#00ccb0',
    user_header: 0,
    music: {
        music_switch: '0',
        music_url: ''
    }, 
    showStation: false,//默认异常不显示
    err_code: null,
    isShowHead: sessionStorage.getItem('IsShowHead') || false,
    boolplay: false,
    show_schedule: true,//日程显示
    show_coopimg:false,
    loginStatus:false,//是否显示登录按钮
    userlogin:false,//用户是否需要注册
    // mac 桌面所需参数
    showLogin: false,
    nowApp: false,
    openAppList: [],
    dockAppList: [],
    openWidgetList: [],
    site_id:'',
    // volumn: 80,
    launchpad: false,
    openAppListIgndex:0,
    // end
} 