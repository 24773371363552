<template>
  <div class="desktop">
    <div class="top">
      <div class="space"></div>
      <div class="status">
        <div class="datetime" @click.self="showOrHideCalendar">
          {{ timeString }}
          <transition name="fade">
            <el-calendar v-model="nowDate" v-if="isCalendarShow"></el-calendar>
          </transition>
        </div>
        <div class="notification" @click="showOrHideWidget">
          <img src="@/assets/img/home/xiaoxi.svg" alt="">
        </div>
      </div>
    </div>
    <div class="body" @contextmenu.prevent.self="hideAllController(); openMenu($event); "
      @click.stop="hideAllController()">
      <transition name="fade-message">
        <div class="message_box_warp" v-show="isWidgetShow">
          <div class="message_box_sollor">
            <div class="message_title">当医学遇上音乐，某某教授医学公益讲座即将</div>
          <div class="message_common_text"><i class="el-icon-time"></i>距离大会开始时间：</div>
          <div class="message_count_down">
            <CountDown :endDate="1740212399971" @timeUp="func" type="4" :theme="2" :timeUnit="['天','时','分','秒']" />
          </div>
          <div class="message_common_text"><i class="el-icon-location-outline"></i>地址：</div>
          <div class="message_count_down location_box">
            <div class="location_title">
              上海市黄浦区思南路9号（靠近淮海中路、复
            </div>
            <div class="location_btn">
              点击查看
            </div>
          </div>
          <div class="message_common_text"><i class="el-icon-date" :style="{fontSize:'12px'}"></i>日程安排</div>
          <div class="schedule_box" v-for="(item,index) in 1" :key="index">
            <div class="schedule_box_text">
              《科学》最新研究：发现肿瘤免疫治疗新潜…
            </div>
            <div class="schedule_box_personnel">
              <div class="personnel_box">
                <div class="personnel_image" v-for="(item,index) in ['赵','钱','孙','李']" :key="index" :style="{zIndex:`${index}`}">
                {{item}}
              </div>
              </div>
              <i class="el-icon-arrow-right icon"></i>
            </div>
          </div>
          </div>
         
        </div>
    </transition>
      <!-- 桌面显示 -->
      <div class="desktop-app">
        <div v-for="item in deskTopAppList" :key="item.key">
          <div class="app-item" v-on:dblclick="$store.commit('openApp', item)" v-if="!item.hideInDesktop">
            <div class="icon">
              <div class="iconfont" :style="{
                background: item.module_style.module_background_color.selected == 1 ? `url(${item.module_style.module_background_color.module_background_url})` : '',
                backgroundColor: item.module_style.module_background_color.selected == 3 ? item.module_style.module_background_color.customize : '',
              }">
                <img v-if="item.module_style.icon" :src="item.module_style.icon" alt="" />
                <div class="title">{{ item.module_title }}</div>
              </div>

            </div>
          </div>
        </div>
      </div>
      <transition-group name="fade-window" tag="div">
        <div v-for="item in $store.state.openAppList" :key="item.pid">
          <App v-if="!item.outLink" v-show="!item.hide" :app="item" :key="item.pid"></App>
        </div>
      </transition-group>
      <transition name="fade-menu">
        <div v-show="rightMenuVisible" :style="{ left: rightMenuLeft + 'px', top: rightMenuTop + 'px' }"
          class="contextmenu">
          <!-- <div @click="lockScreen">锁定屏幕...</div> -->
          <!-- <hr /> -->
          <div @click="openAppByKey('system_setting')">系统偏好设置...</div>
          <div @click="openAppByKey('system_task')">强制退出...</div>
          <hr />
          <div @click="$message.warning('即将上线，敬请期待')">设置壁纸...</div>
          <div @click="openAppByKey('system_about')">关于我们</div>
        </div>
      </transition>
    </div>
    <Dock></Dock>
  </div>
</template>
<script>
import CountDown from '@/components/CountDown.vue';
import App from "@/components/mac/App.vue";
import Dock from "@/components/mac/Dock.vue";
import Widget from "@/components/mac/Widget.vue";
export default {
  components: {
    App,
    Dock,
    Widget,
    CountDown,
  },
  data() {
    return {
      isCalendarShow: false,
      nowDate: new Date(),
      // volumnDelayTimer: false,
      // volumn: 80,
      // isVolumnShow: false,
      rightMenuVisible: false,
      rightMenuLeft: 0,
      rightMenuTop: 0,
      userName: "",
      // menu: [],
      timeString: "",
      deskTopAppList: [],
      deskTopMenu: [],
      isWidgetShow: true,
    };
  },
  props: {
    moduleContent: Array,
  },
  watch: {
    "$store.state.launchpad"() {
      this.$emit("launchpad", this.$store.state.launchpad);
    },
  },
  created() {
    this.userName = localStorage.getItem("user_name") || "";
    this.deskTopAppList = this.moduleContent;
    this.startTimer();
    this.$store.commit("getDockAppList", this.moduleContent);
  },
  methods: {
    func() {

    },
    /**
     * @description: 显示或隐藏日历
     */
    showOrHideCalendar() {
      this.isCalendarShow = !this.isCalendarShow;
    },
    /**
     * @description: 显示或隐藏音量操作
     */
    // showOrHideVolumn() {
    //   this.isVolumnShow = !this.isVolumnShow;
    //   if (this.isVolumnShow) {
    //     clearTimeout(this.volumnDelayTimer);
    //     this.volumnDelayTimer = setTimeout(() => {
    //       this.isVolumnShow = false;
    //     }, 3000);
    //   }
    // },
    /**
     * @description: 隐藏所有弹出的控制器
     */
    hideAllController() {
      // this.isVolumnShow = false;
      this.rightMenuVisible = false;
      this.isCalendarShow = false;
    },
    /**
     * @description: 打开右键菜单
     */
    openMenu(e) {
      const menuMinWidth = 105;
      const offsetLeft = this.$el.getBoundingClientRect().left; // container margin left
      const offsetWidth = this.$el.offsetWidth; // container width
      const maxLeft = offsetWidth - menuMinWidth; // left boundary
      const left = e.clientX - offsetLeft; // margin right

      if (left > maxLeft) {
        this.rightMenuLeft = maxLeft;
      } else {
        this.rightMenuLeft = left;
      }

      this.rightMenuTop = e.clientY - 30;
      this.rightMenuVisible = true;
    },
    /**
     * @description: 打开时间计时器
     */
    startTimer() {
      setInterval(() => {
        this.timeString = this.tool.formatTime(new Date(), "MM-dd HH:mm");
      }, 1000);
    },
    /**
     * @description: 打开指定key的应用
     */
    openAppByKey(key) {
      this.$store.commit("openAppByKey", key);
    },
    /**
     * @description: 锁定屏幕
     */
    // lockScreen() {
    //   this.$emit("lockScreen");
    // },
    /**
     * @description: 关机
     */
    // shutdown() {
    //   this.$emit("shutdown");
    // },
    /**
     * @description: 注销登录
     */
    // logout() {
    //   this.$emit("logout");
    // },
    showOrHideWidget() {
      this.isWidgetShow = !this.isWidgetShow;
    },
  },
};
</script>
<style>
.top .el-dropdown {
  color: white !important;
  height: 100% !important;
}

.top .el-calendar-day {
  height: 30px !important;

}

.top .is-today {
  background: #4b9efb !important;
  color: white !important;
}
</style>
<style scoped lang="scss">
.desktop {
  display: flex;
  flex-direction: column;
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  overflow: hidden;
  text-shadow: 0px 2px 2px rgba(0, 0, 0, 0.1);

  .top {
    color: white;
    height: 28px;
    background-color: rgba(0, 0, 0, 0.3);
    backdrop-filter: blur(20px);
    display: flex;
    flex-direction: row;
    font-size: 14px;
    align-items: center;
    justify-content: center;
    padding: 0px 5px;
    z-index: 100;

    .logo {
      height: 100%;
      align-items: center;
      justify-content: center;
      padding: 0px 15px;
      cursor: pointer;
      position: relative;
      display: flex;
      align-items: center;
      justify-content: center;

      .iconfont {
        font-size: 16px;
        margin-top: -3px;
      }

      .el-select {
        position: absolute;
        opacity: 0;
      }
    }

    .logo:hover {
      background-color: rgba(255, 255, 255, 0.1);
    }

    .space {
      flex-grow: 1;
    }

    .menu {
      display: flex;
      flex-direction: row;
      font-size: 13px;
      height: 100%;
      font-weight: 500;

      .item {
        font-size: 13px;
        padding: 0px 15px;
        display: inline-block;
        flex-grow: 1;
        cursor: pointer;
        display: flex;
        height: 100%;
        align-items: center;
        justify-content: center;
      }

      .item:hover {
        background-color: rgba(255, 255, 255, 0.1);
      }
    }

    .status {
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      height: 100%;
      font-family: 'Gotham-Book';

      .audio {
        cursor: pointer;
        padding: 0px 10px;
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        text-align: center;
        position: relative;

        .iconfont {
          font-size: 20px;
        }

        .el-slider {
          position: absolute;
          top: 40px;
          height: 80px;
        }
      }

      .audio:hover {
        background-color: rgba(255, 255, 255, 0.1);
      }

      .datetime {
        cursor: pointer;
        padding: 0px 10px;
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        text-align: center;
        position: relative;

        .el-calendar {
          color: #333;
          background: rgba(255, 255, 255, 0.98);
          position: fixed;
          top: 40px;
          right: 20px;
          width: 500px;
          border-radius: 10px;
          box-shadow: 0px 0px 20px rgba(0,0,0,.5)
        }
      }

      .datetime:hover {
        background-color: rgba(255, 255, 255, 0.1);
      }

      .notification {
        cursor: pointer;
        padding: 0px 10px;
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        text-align: center;

        img {
          width: auto;
          height: 20px;
        }

        .notification:hover {
          background-color: rgba(255, 255, 255, 0.1);
        }
      }
    }
  }

  .body {
    flex-grow: 1;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    position: relative;

    .message_box_warp {
      position: absolute;
      right: 10px;
      top: 10px;
      width: 350px;
      max-height: 600px;
      background: #F7F8FA;
      font-size: 14px;
      padding: 30px 24px;
      border-radius: 3px;
      overflow: hidden;
      .message_box_sollor{
        max-height: calc(600px - 60px);
        overflow-y: scroll;
      }
      .message_title {
        font-size: 17px;
        font-weight: bold;
        color: #1D2129;
        margin-bottom: 34px;
        display: -webkit-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 2;
        overflow: hidden;
        text-overflow: ellipsis;
      }

      .message_common_text {
        font-size: 12px;
        color: #4E5969;
        margin-bottom: 12px;

        i {
          margin-right: 12px;
          font-size: 14px;
          color: #4E5969;
          font-weight: 600;
        }
      }

      .message_count_down {
        background: #FFFFFF;
        border-radius: 4px;
        height: 40px;
        display: flex;
        align-items: center;
        margin-bottom: 24px;
      }

      .location_box {
        justify-content: space-between;
        padding: 12px 16px;
        font-size: 12px;

        .location_title {
          width: 200px;
          overflow: hidden;
          white-space: nowrap;
          text-overflow: ellipsis;
        }

        .location_btn {
          color: #165DFF;
          cursor: pointer;
        }
      }

      .schedule_box {
        background: #FFFFFF;
        border-radius: 4px;
        padding: 23px 17px 0;
        
        &:last-child  .schedule_box_personnel{
          border-bottom: none;
        }
        .schedule_box_text {
          font-size: 14px;
          color: #1D2129;
          width: 100%;
          overflow: hidden;
          white-space: nowrap;
          text-overflow: ellipsis;
        }

        .schedule_box_personnel {
          display: flex;
          align-items: center;
          justify-content: space-between;
          padding: 16px 0;
          border-bottom: 1px solid #E5E6EB;
          .personnel_box{
            height: 32px;
            position:relative;
            .personnel_image{
              width: 32px;
              height: 32px;
              background: #175FFF;
              border-radius: 50%;
              position: absolute;
              top: 0;
              color: #fff;
              text-align: center;
              line-height: 32px;
              &:nth-child(2){
                left: 25px;
              }
              &:nth-child(3){
                left: 25px*2;
              }
              &:nth-child(4){
                left: 25px*3;
              }
            }
          }
          .icon {
            color: #4E5969;
            font-weight: bold;
          }
        }
      }
    }

    .desktop-app {
      position: fixed;
      right: 0;
      top: 0;
      bottom: 0;
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: flex-end;
      padding: 20px;
      flex-wrap: wrap-reverse;

      .app-item {
        // padding: 10px 0px;
        flex-direction: column;
        text-align: center;
        text-shadow: 0px 0px 2px rgba(0, 0, 0, .5);
        cursor: pointer;
        border-radius: 10px;
        border: 2px solid transparent;
        justify-content: center;
        align-items: center;
        box-sizing: border-box;
        width: 80px;
        height: 80px;

        .icon {
          width: 100%;
          height: 100%;
          display: flex;
          justify-content: center;
          align-items: center;
          text-align: center;
          flex-direction: column;
        }

        img {
          width: auto;
          height: 25px;
        }

        .iconfont {
          border-radius: 10px;
          display: flex;
          justify-content: center;
          align-items: center;
          flex-direction: column;
          width: 65px;
          height: 65px;
          background-size: cover !important;
        }

        .title {
          font-size: 12px;
          margin-top: 5px;
          white-space: nowrap;
          text-overflow: ellipsis;
          overflow: hidden;
        }
      }

      .app-item:hover {
        border: 2px solid rgba(255, 255, 255, 0.5);
      }
    }

    .contextmenu {
      position: absolute;
      background: rgba(255, 255, 255, 0.9);
      border-radius: 5px;
      box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.3);
      color: #333;
      font-size: 14px;
      text-align: left;
      width: 200px;
      overflow: hidden;
      padding: 2px 0px;
      text-shadow: none;
      z-index: 100;

      hr {
        border: none;
        border-top: 1px solid #ddd;
      }

      div {
        cursor: pointer;
        font-size: 13px !important;
        color: #333;
        border-radius: 5px;
        line-height: 2;
        padding: 0px 12px;
        display: flex;
        align-items: center;
        margin: 3px 5px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }

      div:hover {
        background: #4b9efb;
        color: white;
        border-radius: 5px;
      }
    }
  }

  .footer {
    color: white;
    display: flex;
    z-index: 100;
    margin-bottom: 4px;
  }
}
</style>
