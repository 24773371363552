<template>
  <div class="home-page-box" :style="cover_set.show_cover && coverStatus == 0 ? 'z-index:222;' : ''">
    <loading v-if="isLoading" style="margin-top: 210px"></loading>
    <Loginindex v-if="overall == 1 && !isLoading" @registered="registered" :page_style="page_style"></Loginindex>
    <div class="main_home" v-show="overall == 0 && !isLoading">
      <van-empty :image="require('@/assets/closed.svg')" v-if="err_code == 3001" />
      <van-empty :image="require('@/assets/deled.svg')" v-if="err_code == 3002" />
      <van-empty :image="require('@/assets/not_started.svg')" v-if="err_code == 3003" />
      <van-empty :image="require('@/assets/finished.svg')" v-if="err_code == 3004" />
      <van-empty :image="require('@/assets/station_null.svg')" v-if="err_code == 3000" />
      <div class="home-box" id="scroll" v-if="err_code == 200">
        <div class="micro-sitecover" v-if="cover_set.show_cover && coverStatus == 0">
          <p>{{ cover_set.count_down }}s</p>
          <img :src="cover_set.micro_sitecover" alt="" />
        </div>
        <div class="home is-main-width" id="my-node" ref="imageToFile" :style="{
          background: background_type == 2 ? `url('${page_style.background_setting.background_picture_url}')` : page_style.background_setting.background_color,
          backgroundSize: background_image_type ? '100% 100%' : '100%',
        }" v-else>
          <div class="home-top" :style="{ height: page_style.carousel.banner_area_height / 100 + 'rem', }">
            <van-swipe class="my-swipe" :autoplay="Number(page_style.carousel.banner_duration) * 1000"
              indicator-color="white" v-if="page_style.carousel.hide_banner == 0"
              :style="{ height: page_style.carousel.banner_area_height / 100 + 'rem', }">
              <van-swipe-item v-for="(i, j) in page_style.carousel.carousel_link" :key="j + 0.5" @click="bannerSkip(i)">
                <img class="swipe-img" :src="i.banner_img_url" style="height: 100%; width: 100%" />
              </van-swipe-item>
            </van-swipe>

          </div>
          <div class="home-scoll"
            :style="{ minHeight: showUserheader && (user_header == 1) ? 'calc(100vh - ' + page_style.carousel.banner_area_height / 100 + 'rem - .4rem)' : 'calc(100vh - ' + page_style.carousel.banner_area_height / 100 + 'rem )' }">
            <div class="home-bottom" v-if="site_model == 1">
              <div class="module" :style="{
                flex: `0 0 ${Number(100 / module_columns)}%`,
                width: item.width + 'rem',
                height: item.height + 'rem',
                zIndex: item.module_level,
                padding: page_style.page_margins == 1 ? '.05rem' : '0',
              }" v-for="(item, index) in module_content" :key="index + 'B'"
                @click.stop="constraintmoduleClick(item, module_content)">
                <div class="item-box-default flex-center" :style="{
                  width: (page_style.page_margins == 1 ? item.module_size.width > 1 ? item.module_size.w + 0.1 * (item.module_size.width - 1) : item.module_size.w : item.module_size.w) + 'rem',
                  height: (page_style.page_margins == 1 ? item.module_size.height > 1 ? item.module_size.h + 0.1 * (item.module_size.height - 1) : item.module_size.h : item.module_size.height > 1 ? item.module_size.h * item.module_size.height : item.module_size.h) + 'rem',
                  background: item.module_background_color.selected == 1 ? `url(${item.module_background_color.module_background_url})` : '',
                  transform: `scale(${item.module_size.zoom != '' ? Number(item.module_size.zoom) : '1'}) rotateZ(${Number(item.module_size.spin) != '' ? Number(item.module_size.spin) : '0'}deg)`,
                  backgroundColor: item.module_background_color.selected == 3 ? item.module_background_color.customize : '',
                  backgroundSize: '100%',
                  borderRadius: radius[item.module_shape - 1],
                }">
                  <div style="width: 100%" v-if="![21, 22].includes(item.module_type)">
                    <div class="img-box flex-center constraint_img_box" v-if="item.icon">
                      <img :src="item.icon" alt="" />
                    </div>

                    <p v-show="item.main_title_show_hidden == 1" :style="{ color: item.main_title_color, }">
                      {{ item.module_title }}
                    </p>
                    <p class="subtitle" :style="{ color: item.subtitle_color, }">
                      {{ item.subtitle }}
                    </p>
                  </div>
                  <div class="video_box" v-if="[22].includes(item.module_type)">
                    <video class="video_item" :src="item.module_style.module_background_color.home_video" :style="{
                      borderRadius: radius[item.module_shape - 1]
                    }" ref="siteOnePlay"></video>
                    <img class="play_btns" src="@/assets/playbtn.svg" alt="" v-show="!playVideoStatus">
                  </div>
                </div>
              </div>
              <van-dialog v-model="dialog_show" @confirm="confirm" @cancel="cancel" confirmButtonText="是"
                cancelButtonText="否" :confirmButtonColor="themecolors" show-cancel-button>
                <div class="dialog-title">温馨提示</div>
                <div class="dialog-content" v-html="dialog_content"></div>
              </van-dialog>
            </div>
            <div class="fileModel" v-if="site_model == 2">
              <div :class="!fileLayout ? 'home-bottom modle-phone-box' : 'home-bottom modle-phone-box file-phone-box'"
                :style="{
                  height: main_height / 100 + 'rem',
                  minHeight: !fileLayout ? '0px' : '542px',
                }" v-if="site_model == 2">
                <!-- 自定义通用模块 -->
                <div v-if="!fileLayout">
                  <div class="module vdr" v-for="(item, index) in module_content" :style="{
                    width: (item.module_size.width * proportionNumber) / 100 + 'rem',
                    height: (item.module_size.height * proportionNumber) / 100 + 'rem',
                    zIndex: item.module_size.index,
                    padding: page_style.page_margins == 1 ? '0' : '0',
                    transform: `translate(${(item.module_size.x * proportionNumber) / 100}rem, ${(item.module_size.y * proportionNumber) / 100}rem) rotate(${item.module_size.spin}deg)`,
                  }" :key="index" @click.stop="moduleClick(item, module_content)">
                    <div class="item-box-default flex-center" :style="{
                      ...(item.module_style.module_background_switch == 1 ? {
                        background: item.module_style.module_background_color.selected != 2 ? `url(${item.module_style.module_background_color.module_background_url})` : '',
                        backgroundColor:  item.module_style.module_background_color.selected == 3 ? item.module_style.module_background_color.customize : '',
                      } : {}),
                      borderRadius: radius[item.module_style.module_shape - 1],
                      backgroundSize: item.module_type == 17 ? 'contain' : 'cover',
                      backgroundRepeat: 'no-repeat',
                    }">
                      <div style="width: 100%" v-if="![21, 22].includes(item.module_type)">
                        <div :style="icon_style(item.icon_size)" class="img-box flex-center position-icon"
                          v-if="item.module_style.icon">
                          <img :src="item.module_style.icon" alt="" v-show="item.module_type != 17" />
                        </div>
                        <span class="position-title"
                          v-if="item.module_title_content.main_title_show_hidden == 1 && item.module_type != 17"
                          :style="title_style(item.module_title_content)">
                          {{ item.module_title }}
                        </span>
                      </div>
                      <div class="video_box"v-if="[22].includes(item.module_type)" @click="videoBoxClick">
                        <video class="video_item" :src="item.module_style.module_background_color.home_video" :style="{
                          borderRadius: radius[item.module_style.module_shape - 1]
                        }" ref="siteTwoPlay" ></video>
                        <img class="play_btns" src="@/assets/playbtn.svg" alt="" v-show="!playVideoStatus">
                      </div>
                    </div>
                  </div>
                </div>
                <!-- 文件模板 -->
                <div class="file-model" v-else @click.stop="moduleClick(item, module_content)" :style="{
                  background: item.module_style.module_background_color.selected != 2 ? `url(${item.module_style.module_background_color.module_background_url})` : '',
                  backgroundColor: item.module_style.module_background_color.selected != 2 && item.module_style.module_background_color.module_background_url == '' ? item.module_style.module_background_color.customize : '',
                  borderRadius: radius[item.module_shape - 1],
                }">
                  <div class="img-box flex-center" v-if="item.module_style.icon && ![21, 22].includes(item.module_type)">
                    <img :src="item.module_style.icon" alt="" v-show="item.module_type != 17" />
                  </div>
                  <p v-show="item.module_title_content.main_title_show_hidden == 1 && ![17, 21, 22].includes(item.module_type)"
                    :style="{ color: item.module_title_content.main_title_color }">
                    {{ item.module_title }}
                  </p>
                  <p class="subtitle" :style="{ color: item.subtitle_color, }">
                    {{ item.subtitle }}
                  </p>
                </div>
                <!-- 文件模板 -->
                <van-dialog v-model="dialog_show" @confirm="confirm" @cancel="cancel" confirmButtonText="是"
                  cancelButtonText="否" :confirmButtonColor="themecolors" show-cancel-button>
                  <div class="dialog-title">温馨提示</div>
                  <div class="dialog-content" v-html="dialog_content"></div>
                </van-dialog>
              </div>
              <Support v-if="fileLayout"></Support>
            </div>
            <Support v-if="!fileLayout" class="keep_record"></Support>
          </div>
          <img class="share-img" :src="img" v-if="img" />
        </div>
      </div>
      <sideBar v-if="sideBarShow"></sideBar>
    </div>
  </div>
</template>

<script>
import sideBar from "@/components/sidebar"; // 浮窗
import Support from "@/components/support"; // 技术支持
import Header from "@/components/header";
import domtoimage from "dom-to-image";
import html2canvas from "html2canvas";
import { hexToRgba } from "@/js/until.js";
import Loginindex from "@/views/Login/Loginindex.vue";
import { mapState } from "vuex";
import Loading from "@/components/loading/loading";

export default {
  props: ['showUserheader'],
  name: "Home",
  components: {
    Header,
    sideBar,
    Support,
    domtoimage,
    html2canvas,
    Loginindex,
    Loading,
  },
  data() {
    return {
      sideBarShow: false,//判断首页是否显示浮窗
      background_image_type: 0, //kv图片平铺拉伸
      background_type: null,//首页显示是背景颜色还是背景图片
      audio: {
        playing: true,
      },
      isLoading: true,
      module_type: [
        "空白页面",
        "站外链接",
        "站内页面",
        "新闻资讯",
        "合作伙伴",
        "图片集",
        "视频",
        "文档",
        "日程",
        "云展厅",
        "参会者",
        "直播列表",
        "报名缴费",
        "酒店信息",
        "防疫码",
      ],
      system: null /** 设备系统 */,
      img: "",
      err_code: null,
      site_id: "",
      page_id: "",
      cover_set: {
        count_down: 3 /** 倒计时 */,
        show_cover: false /** 显示封面 */,
        micro_sitecover: "" /** 封面 */,
      } /** 封面设置 */,
      page_style: {
        background_setting: {
          background_color: "#fff",
          background_picture_url: "",
        },
        carousel: {},
        hide_show: {},
      } /** 背景配置 */,
      module_content: {} /** 模块配置 */,
      module_columns: null /** 模块列数 */,
      radius: ["0px", "6px", "50%"] /** 模块圆角类型 */,
      z_width: null,
      title: "轻微站",
      module_width: null,
      module_height: null,
      coverStatus: 0,
      site_url: "",
      code: "",
      redirect_url: "",
      user_info: {},
      token: "",
      current: {} /** 当前点击模块项 */,
      dialog_content: "" /** 表单询问内容 */,
      dialog_show: false /** 表单询问弹窗显示 */,
      is_reg: 0 /** 模块注册状态 */,
      is_login: 0 /** 模块是否开启登录 */,
      overall: 0 /** 是否是全局表单 */,
      boolplay: false,
      music_url: "", //音乐地址
      music_switch: "0",
      one_pace: true,
      touchplay: true,
      station_collect: 0,
      proportionNumber: 1,
      phoneHeight: 0, //拖动布局盒子高度
      site_model: 1, //区分是否是自定义  1是默认  2是自定义
      startX: 0,
      startY: 0,
      maxModelHight: 0,
      fileLayout: false,
      main_height: 667,
      playVideoStatus: false
    };
  },
  created() {
    const url = new URL(window.location.href);
    if (url.searchParams.get("code") || url.searchParams.get("referral_code")) {
      this.$router.push({
        query: {}
      })
      sessionStorage.removeItem('microcode')
    }
    this.site_url = window.sessionStorage.getItem("siteUrl");
  },
  mounted() {
    this.$store.commit("changeIsShowHead", true);
    sessionStorage.setItem("IsShowHead", true);
    this.coverStatus = sessionStorage.getItem("coverStatus");
    let timer = setInterval(() => {
      if ( window.sessionStorage.getItem("formquery") && window.sessionStorage.getItem("overall")  ) {
        clearInterval(timer);
        this.overall = window.sessionStorage.getItem("overall");
        window.sessionStorage.getItem("coverStatus") == 0 ? window.sessionStorage.getItem("formquery") == 1 && this.front_desk_home_show(this.site_url) : this.front_desk_home_show(this.site_url);
        return;
      }
    }, 0);
    // this.getSystem();  获取设备方法 暂时没有用到
    this.$store.commit("changeIsShowHead", true);
    sessionStorage.setItem("IsShowHead", true);
    // if(this.music_switch == 1){
    //   let that = this;
    //   document.addEventListener("touchstart", function () {
    //     if (that.touchplay) {
    //       that.getAudioPlay();
    //       return;
    //     }
    //   });
    //   document.addEventListener('WeixinJSBridgeReady', function () {
    //     that.getAudioPlay();
    //   })
    // }
    // this.$forceUpdate()
  },
  computed: {
    ...mapState(["themecolors", "user_header"]),
  },
  methods: {
    videoBoxClick() {
      if (this.site_model == 1 && this.$refs.siteOnePlay[0]) {
        if (this.$refs.siteOnePlay[0].paused) {
          this.$refs.siteOnePlay[0].play()
          this.playVideoStatus = true
        } else {
          this.$refs.siteOnePlay[0].pause()
          this.playVideoStatus = false
        }
        return
      }
      if (this.site_model == 2 && this.$refs.siteTwoPlay[0]) {
        if (this.$refs.siteTwoPlay[0].paused) {
          this.$refs.siteTwoPlay[0].play()
          this.playVideoStatus = true
        } else {
          this.$refs.siteTwoPlay[0].pause()
          this.playVideoStatus = false
        }
      }
    },
    // icon  位置
    icon_style(val) {
      let style = {
        left: val.x / 100 + 'rem',
        top: val.y / 100 + 'rem',
        width: val.width / 100 + 'rem',
        height: val.height / 100 + 'rem'
      }
      return style
    },
    title_style(val) {
      let style = {
        left: val.title_size.x / 100 + 'rem',
        top: val.title_size.y / 100 + 'rem',
        fontSize: val.font_size / 100 + 'rem',
        fontWeight: val.font_weight,
        color: val.main_title_color
      }
      return style
    },
    registered(status) {
      this.overall = status;
    },
    // 计算列宽
    clientWidth() {
      // let clientWidth = document.body.clientWidth;
      // let width = (clientWidth / 100 - 0.15) > 3.6 ? 3.6 : (clientWidth / 100 - 0.15);
      let width = 3.6;
      this.module_content.forEach((item) => {
        let w_ratio = parseInt(item.module_size.width);
        let h_ratio = parseInt(item.module_size.height);
        item.width = Number((width / this.module_columns).toFixed(2));
        item.height = Number((width / this.module_columns).toFixed(2));
        if (w_ratio == 1) {
          this.module_width = item.width;
          this.module_height = item.height;
        }
        item.module_size.w =
          this.page_style.page_margins == 0
            ? Number(w_ratio > 0 ? item.width * w_ratio : item.width)
            : Number(
              w_ratio > 0
                ? item.width * w_ratio - 0.1 * w_ratio
                : item.width - 0.1
            );
        item.module_size.h =
          this.page_style.page_margins == 0
            ? Number(h_ratio > 0 ? item.height * h_ratio : item.height)
            : Number(
              h_ratio > 0
                ? item.height * h_ratio - 0.1 * h_ratio
                : item.height - 0.1
            );
      });
    },

    /** 封面倒计时 */
    countDown() {
      this.cover_set.micro_sitecover = this.page_style.micro_sitecover.img_url;
      if (this.cover_set.micro_sitecover != "") {
        this.cover_set.show_cover = true;
        let timer = setInterval(() => {
          this.cover_set.count_down--;
          if (this.cover_set.count_down <= 0) {
            this.cover_set.show_cover = false;
            clearInterval(timer);
            // this.customLayout();
            window.sessionStorage.setItem("coverStatus", 1);
            return;
          }
        }, 1000);
      }
    },
    clearHeight() {
      let obj = {
        width: 0,
        height: 0,
        module_level: -1,
        // title_position: {
        //   title_position: 1,
        // },
        // subtitle_position: {
        //   subtitle_position: 1,
        // },
        module_size: {
          width: 1,
          height: 1,
        },
        module_background_color: {
          selected: "",
        },
      };
      this.module_content.forEach((item, index) => {
        // || item.module_size.height>1
        if (item.module_size.width > 1 || item.module_size.height > 1) {
          let width = item.module_size.width;
          let height = item.module_size.height;
          let COL = this.module_columns;
          width < 3 && width > 1
            ? this.module_content.splice(index + width - 1, 0, obj)
            : "";
          let num = width <= 2 ? height - 1 : width == 3 ? height : "";
          for (let i = 1; i <= num; i++) {
            if (width > 1) {
              for (let j = 1; j <= width; j++) {
                let aa =
                  COL == width && width > 2
                    ? index + COL * i + j - COL
                    : index + COL * i;
                this.module_content.splice(aa, 0, obj);
              }
            } else {
              this.module_content.splice(index + COL * i, 0, obj);
            }
          }
          COL == width && width > 2
            ? this.module_content.splice(index + 1, 1)
            : "";
        }
      });
    },

    /** 首页展示 */
    front_desk_home_show(site_url) {
      this.$store
        .dispatch("front_desk_home_show", {
          site_url: site_url,
        })
        .then((res) => {
          this.err_code = res.data.code;
          this.isLoading = false;
          if (res.data.code == 200) {
            sessionStorage.setItem(
              "technical_support",
              res.data.data.page_style.technical_support
            );
            this.background_image_type = res.data.data.page_style.background_image_type;
            this.background_type = res.data.data.page_style.background_setting.type;
            // this.$store.commit("changeHeaderMusic", true)
            // this.$store.commit('changeUserHeader', res.data.data.page_style.user_header)
            this.$store.commit("changeMusicSwitch", {
              music_switch: res.data.data.page_style.background_music.music_switch,
              music_url: res.data.data.page_style.background_music.music_url,
            });
            this.site_id = res.data.data.site_id;
            this.page_id = res.data.data.page_id;
            this.page_style = res.data.data.page_style;
            this.site_model = res.data.data.site_model;
            this.sideBarShow = this.site_id == this.page_id ? false : true//判断是否是首页
            sessionStorage.setItem('homeUrl', res.data.data.site_url)  //存储首页的url
            if (this.site_model == undefined) {
              this.site_model = 1;
            }
            window.sessionStorage.setItem("background_setting", JSON.stringify(this.page_style.background_setting));
            this.$cookies.set("site_id", this.site_id);
            this.$cookies.set("supportInformation", this.page_style.hide_show.support_information);
            this.module_columns = this.page_style.number_of_module_columns; //  模块列数
            this.module_content = res.data.data.module_content ? res.data.data.module_content : [];
            if (this.module_content) {
              let max_num = 0;
              this.module_content.forEach((item) => {
                if (item.module_size.y + item.module_size.height > max_num) {
                  max_num = item.module_size.y + item.module_size.height;
                }
              });
              this.main_height = max_num;
            }
            this.title = res.data.data.title;
            document.title = this.title;
            sessionStorage.setItem('wei-title', res.data.data.title)
            if (this.overall != 1 && this.coverStatus != 0) {
              this.customLayout();
            }
            if (this.page_style.micro_sitecover.img_url && this.overall != 1)
              this.countDown(); // 引导页倒计时 //计算2
            this.clearHeight();
            if (this.$cookies.isKey("microtoken")) {
              this.$store.dispatch("visit_site_record", {
                site_id: this.site_id,
                site_url: sessionStorage.getItem("siteUrl"),
              }).then((res) => {
                if (res.data.code == 200) {
                }
              });
              this.$store.dispatch("get_site_record_collection_status", {
                site_id: this.site_id,
                site_url: sessionStorage.getItem("siteUrl"),
              }).then((res) => {
                if (localStorage.getItem("station_collect")) {
                  localStorage.removeItem("station_collect");
                }
                this.station_collect = res.data.data.collection_status;
                localStorage.setItem("station_collect", res.data.data.collection_status
                );
              });
            }
            let rgbathemecolor, themecolors;
            if (res.data.data.page_style.theme_color) {
              rgbathemecolor = hexToRgba(res.data.data.page_style.theme_color, 0.8);
              themecolors = res.data.data.page_style.theme_color;
            } else {
              rgbathemecolor = hexToRgba("#00ccb0", 0.8);
              themecolors = "#00ccb0";
            }
            let tech_support_colors = res.data.data.page_style.tech_support_colors;
            window.sessionStorage.setItem("themecolor", rgbathemecolor);
            window.sessionStorage.setItem("themecolors", themecolors);
            window.sessionStorage.setItem("techSupporTcolors", tech_support_colors);
            this.$store.commit("changethemecolor", rgbathemecolor);
            this.$store.commit("changethemecolors", themecolors);
            this.$store.commit("changetechSupporTcolors", tech_support_colors);
            /**
             * custom_css 自定义css
             * custom_js  自定义js
             * hide_show 显示隐藏中英文 。。。
             */
            this.clientWidth(); //计算1
            // alert(localStorage.getItem("microtoken"),this.coverStatus)
            // &&localStorage.getItem("microtoken")
            if (this.coverStatus == 0) {
              this.$store.dispatch("site_view_count",{
                site_id:this.site_id,
              }).then(res=>{
                if(res.data.code == 200) window.sessionStorage.setItem('coverStatus', 1)
              })
              setTimeout(() => {
                this.$store.dispatch("user_information_collection", {
                  site_id: this.site_id,
                  token: this.token,
                  referral_code: sessionStorage.getItem("referralCode"),
                }).then((res) => {
                  if (res.data.code == 200)
                    window.sessionStorage.setItem("coverStatus", 1);
                });
              }, 1000);
            }
            // 存储专家库的模块id
            this.module_content.forEach((item) => {
              if (item.module_type == 11) {
                window.sessionStorage.setItem("module_id", item.random_number);
              }
            });
          } else if (res.data.code === 401) {
            // 状态为401 清空token 重载页面数据
            this.$store.commit("changeHeaderMusic", false);
            localStorage.removeItem("wxmessage");
            localStorage.removeItem("user");
            localStorage.removeItem("token");
            location.reload();
          } else {
            this.$store.commit("changeHeaderMusic", false);
          }
        });
    },
    //注册表单成功后判断如果是自定义布局
    customLayout() {
      //拖拽布局
      if (this.site_model == 2) {
        this.$nextTick().then(() => {
          let box = document.querySelector(".modle-phone-box");
          this.proportionNumber = parseFloat(375 / box.offsetWidth).toFixed(4);
          this.module_content.forEach((item) => {
            item.module_size.width = Math.round(
              item.module_size.width / this.proportionNumber
            );
            item.module_size.height = Math.round(
              item.module_size.height / this.proportionNumber
            );
            item.module_size.x = Math.round(
              item.module_size.x / this.proportionNumber
            );
            item.module_size.y = Math.round(
              item.module_size.y / this.proportionNumber
            );
          });
          // 获取模块最底部一个模块的 top + 自身高度 = 底部高度
          this.phoneHeight = Math.max.apply(
            Math,
            this.module_content.map((item) => {
              return item.module_size.y + item.module_size.height;
            })
          );
          this.phoneHeight += 80 / this.proportionNumber; //增加高度
          if (this.page_style.template_id == 10) {
            this.fileLayout = true;
          }
        });
      }
    },
    /** 获取设备系统 */
    getSystem() {
      var device_type = navigator.userAgent; //获取userAgent信息
      var md = new MobileDetect(device_type); //初始化mobile-detect
      this.system = md.os(); //获取系统
    },

    sharelick(data) {
      data == 1 && this.shotPic();
    },
    shotPic() {
      document.documentElement.scrollTop = 0;
      let node = document.getElementById("my-node");
      // if(this.system == "iOS"){
      html2canvas(node, {
        width: node.clientWidth,
        height: node.clientHeight,
        useCORS: true,
      }).then((canvas) => {
        // 第一个参数是需要生成截图的元素,第二个是自己需要配置的参数,宽高等
        this.img = canvas.toDataURL("image/png");
      })
        .catch(function onRejected(error) { });
    },

    /** 轮播图跳转 */
    bannerSkip(item) {
      item.jump_link_content != "" && item.jump_link_content != null
        ? (window.location.href = item.jump_link_content)
        : "";
    },

    /** 询问弹窗确认 */
    confirm() {
      this.regSkip(this.current);
    },

    /** 询问弹窗取消 */
    cancel() {
      this.routeSkip();
    },

    /** 九宫模块点击 */
    async constraintmoduleClick(item, list) {
      if (item.module_type == 14) {
        const data = await this.$store.dispatch("get_user_certificate", {
          site_id: this.site_id,
        });
        if (data.data.code == 200) {
          if (data.data.data.payment_voucher) {
            let module_id = item.random_number;
            this.$router.push({
              path: "/invoice",
              query: {
                site_id: this.site_id,
                module_id: module_id,
              },
            });
            return;
          }
        }
      }
      // 保存logoBG背景,logo墙背景,云展厅获取logo墙背景
      if (item.module_type == 5) {
        if (item.module_value.type == 1) {
          localStorage.setItem("logoBG", item.module_value.logo_color);
        } else {
          localStorage.setItem("logoBG", item.module_value.logo_url);
        }
      }
      if (item.module_type == 10) {
        list.forEach((item) => {
          if (item.module_type == 5) {
            if (item.module_value.type == 1) {
              localStorage.setItem("logoBG", item.module_value.logo_color);
            } else {
              localStorage.setItem("logoBG", item.module_value.logo_url);
            }
          }
        });
      }
      this.current = item;
      sessionStorage.setItem("module_value", JSON.stringify(item.module_value));

      // document.title = item.module_title ? item.module_title : this.module_type[item.module_type - 1];
      this.$store.dispatch("inc_module_usage_statistics", {
        site_id: this.site_id,
        module: item.module_type,
        module_id: item.random_number,
      });

      // if ( this.current.module_sign_up_form_login == 1 && !this.$cookies.get("formregister-" + this.site_url) ) {
      if (this.current.module_sign_up_form_login == 1) {
        if (item.module_type != 13) {
          if (this.$cookies.get("microtoken")) {
            const Res = await this.$store.dispatch(
              "check_registered_the_forms",
              {
                site_url: sessionStorage.getItem("siteUrl"),
              }
            );
            if (Res.data.code == 200) {
              this.routeSkip();
            } else {
              if (this.current.compulsory == 1) {
                // 强制表单
                this.regSkip(this.current);
              } else {
                // 询问弹窗
                this.dialog_content = this.current.compulsory_text;
                this.dialog_show = true;
              }
            }
          } else {
            if (this.current.compulsory == 1) {
              // 强制表单
              this.regSkip(this.current);
            } else {
              // 询问弹窗
              this.dialog_content = this.current.compulsory_text;
              this.dialog_show = true;
            }
          }
        } else {
          if (sessionStorage.getItem("loginHTTP") != "two") {
            sessionStorage.setItem("loginHTTP", "one");
          }
          if (this.current.compulsory == 1) {
            // 强制表单
            this.regSkip(this.current);
          } else {
            // 询问弹窗
            this.dialog_content = this.current.compulsory_text;
            this.dialog_show = true;
          }
        }
      } else {
        this.constraintrouteSkip();
      }
    },
    constraintrouteSkip() {
      let item = this.current;
      let module_type = item.module_type;
      let params = {
        site_id: this.site_id,
        page_id: this.page_id,
        module_id: item.random_number,
      };
      if (
        module_type == 1 ||
        (module_type == 2 && item.module_value.type == 2)
      ) {
        /** 空白页面 */
        window.sessionStorage.setItem("moduleValue", JSON.stringify(item.module_value));
        this.$router.push({ name: "blank" });
      }
      if (module_type == 2) {
        /** 站外链接 1外链 2内嵌 */
        item.module_value.off_site_links != ""
          ? item.module_value.type == 1
            ? (location.href = item.module_value.off_site_links)
            : this.$router.push({
              name: "blank",
              query: { module_type: module_type },
            })
          : "";
      }
      module_type == 3 && item.module_value.website_page != ""
        ? (location.href = item.module_value.website_page)
        : ""; /** 站内页面 */
      module_type == 4 &&
        this.$router.push({
          name: "Articlelist",
          query: params,
        }); /** 新闻资讯 */
      module_type == 5 &&
        this.$router.push({
          name: "cooperation",
          query: params,
        }); /** 合作伙伴 */
      module_type == 6 &&
        this.$router.push({
          name: "PhotoGallery",
          query: params,
        }); /** 图片集   */
      module_type == 7 &&
        this.$router.push({ name: "Video", query: params }); /** 视频     */
      module_type == 8 &&
        this.$router.push({
          name: "Filedownload",
          query: params,
        }); /** 文档     */
      module_type == 9 &&
        this.$router.push({ name: "Schedule", query: params }); /** 日程     */
      if (module_type == 10) {
        params = {
          site_id: this.site_id,
          page_id: this.page_id,
          module_id: item.random_number,
          showroom_type: item.module_value.showroom_type,
        };
        this.$router.push({ name: "Exhibition", query: params });
      }
      // module_type == 10 && this.$router.push({ name: "Exhibition", query: params, }); /** 云展厅   */
      module_type == 11 &&
        this.$router.push({ name: "attend", query: params }); /** 参会者   */
      module_type == 12 &&
        this.$router.push({
          name: "liveList",
          query: params,
        }); /** 直播列表   */
      module_type == 13 && this.regSkip(this.current); /** 登录模块 */
      module_type == 14 && this.$router.push({ name: "RegPayment", query: params }); //报名缴费
      module_type == 15 && this.$router.push({ name: "hotel", query: params }); //酒店
      module_type == 16 && this.$router.push({ name: "antiepidemic", query: params }); //健康码
      module_type == 18 && this.$router.push({ name: "Addendum", query: params }) //征文收集
      module_type == 19 && this.$router.push({ name: "ConferenceAddress", query: params })//会场地址
    },
    /** 模块点击 */
    async moduleClick(item, list) {
      if (item.module_type == 14) {
        const data = await this.$store.dispatch("get_user_certificate", {
          site_id: this.site_id,
        });
        if (data.data.code == 200) {
          if (data.data.data.payment_voucher) {
            let module_id = item.random_number;
            this.$router.push({
              path: "/invoice",
              query: {
                site_id: this.site_id,
                module_id: module_id,
              },
            });
            return;
          }
        }
      }
      // 保存logoBG背景,logo墙背景,云展厅获取logo墙背景
      if (item.module_type == 5) {
        if (item.configure.module_value.type == 1) {
          localStorage.setItem("logoBG", item.configure.module_value.logo_color);
        } else {
          localStorage.setItem("logoBG", item.configure.module_value.logo_url);
        }
      }
      if (item.module_type == 10) {
        list.forEach((item) => {
          if (item.module_type == 5) {
            if (item.configure.module_value.type == 1) {
              localStorage.setItem("logoBG", item.configure.module_value.logo_color);
            } else {
              localStorage.setItem("logoBG", item.configure.module_value.logo_url);
            }
          }
        });
      }
      this.current = item;
      sessionStorage.setItem("module_value", JSON.stringify(item.configure.module_value));

      this.$store.dispatch("inc_module_usage_statistics", {
        site_id: this.site_id,
        module: item.module_type,
        module_id: item.random_number,
      });
      if (this.current.configure.module_sign_up_form_login == 1) {
        if (item.module_type != 13) {
          if (this.$cookies.get("microtoken")) {
            const Res = await this.$store.dispatch(
              "check_registered_the_forms",
              {
                site_url: sessionStorage.getItem("siteUrl"),
              }
            );
            if (Res.data.code == 200) {
              this.routeSkip();
            } else {
              if (this.current.configure.compulsory == 1) {
                // 强制表单
                this.regSkip(this.current);
              } else {
                // 询问弹窗
                this.dialog_content = this.current.configure.compulsory_text;
                this.dialog_show = true;
              }
            }
          } else {
            if (this.current.configure.compulsory == 1) {
              // 强制表单
              this.regSkip(this.current);
            } else {
              // 询问弹窗
              this.dialog_content = this.current.configure.compulsory_text;
              this.dialog_show = true;
            }
          }
        } else {
          if (sessionStorage.getItem("loginHTTP") != "two") {
            sessionStorage.setItem("loginHTTP", "one");
          }
          if (this.current.configure.compulsory == 1) {
            // 强制表单
            this.regSkip(this.current);
          } else {
            // 询问弹窗
            this.dialog_content = this.current.configure.compulsory_text;
            this.dialog_show = true;
          }
        }
      } else {
        this.routeSkip();
      }
    },
    regSkip(current) {
      let params = {
        // is_login:this.is_login,
        is_reg: this.is_reg,
        module_type: current.module_type,
        site_id: this.site_id,
        page_id: this.page_id,
        module_id: current.random_number,
      };
      this.$router.push({ name: "Loginindex", query: params });
    },

    /** 模块路由跳转 */
    routeSkip() {
      let item = this.current;
      let module_type = item.module_type;
      let params = {
        site_id: this.site_id,
        page_id: this.page_id,
        module_id: item.random_number,
      };
      if (
        module_type == 1 ||
        (module_type == 2 && item.configure.module_value.type == 2)
      ) {
        /** 空白页面 */
        window.sessionStorage.setItem("moduleValue", JSON.stringify(item.configure.module_value));
        this.$router.push({ name: "blank" });
      }
      if (module_type == 2) {
        /** 站外链接 1外链 2内嵌 */
        item.configure.module_value.off_site_links ? item.configure.module_value.type == 1 ? (location.href = item.configure.module_value.off_site_links)
          : this.$router.push({ name: "blank", query: { module_type: module_type }, }) : "";
      }
      module_type == 3 && item.configure.module_value.website_page ? (location.href = item.configure.module_value.website_page) : ""; /** 站内页面 */
      module_type == 4 &&
        this.$router.push({
          name: "Articlelist",
          query: params,
        }); /** 新闻资讯 */
      module_type == 5 &&
        this.$router.push({
          name: "cooperation",
          query: params,
        }); /** 合作伙伴 */
      module_type == 6 &&
        this.$router.push({
          name: "PhotoGallery",
          query: params,
        }); /** 图片集   */
      module_type == 7 &&
        this.$router.push({ name: "Video", query: params }); /** 视频     */
      module_type == 8 &&
        this.$router.push({
          name: "Filedownload",
          query: params,
        }); /** 文档     */
      module_type == 9 &&
        this.$router.push({ name: "Schedule", query: params }); /** 日程     */
      if (module_type == 10) {
        params = {
          site_id: this.site_id,
          page_id: this.page_id,
          module_id: item.random_number,
          showroom_type: item.configure.module_value.showroom_type,
        };
        this.$router.push({ name: "Exhibition", query: params });
      }
      // module_type == 10 && this.$router.push({ name: "Exhibition", query: params, }); /** 云展厅   */
      module_type == 11 &&
        this.$router.push({ name: "attend", query: params }); /** 参会者   */
      module_type == 12 &&
        this.$router.push({
          name: "liveList",
          query: params,
        }); /** 直播列表   */
      module_type == 13 && this.regSkip(this.current); /** 登录模块 */
      module_type == 14 && this.$router.push({ name: "RegPayment", query: params }); //报名缴费
      module_type == 15 && this.$router.push({ name: "hotel", query: params }); //酒店
      module_type == 16 && this.$router.push({ name: "antiepidemic", query: params }); //健康码
      module_type == 18 && this.$router.push({ name: "Addendum", query: params }) //征文收集
      module_type == 19 && this.$router.push({ name: "ConferenceAddress", query: params })//会场地址
      module_type == 20 && this.$router.push({ name: "meeting", query: params })//会场地址
    },
  },
};
</script>

<style lang="less" scoped>
.home-page-box {
  max-width: 420px !important;
  width: 100vw !important;
  overflow-x: hidden !important;
}

.position-width {
  max-width: 375px !important;
}

.main_home {
  max-width: 420px !important;
  width: 100vw !important;
  height: 100%;
  overflow-x: hidden !important;
}

.flex-center {
  display: flex;
  align-items: center;
}

/deep/.van-empty {
  position: absolute;
  top: 25%;
  left: 50%;
  transform: translateX(-50%);
}

/deep/.van-empty {
  width: 100%;

  .van-empty__image {
    width: 3rem;
    height: 3rem;
  }
}

.home-box::-webkit-scrollbar {
  display: none;
}

.home-box {
  width: 100vw;
  max-width: 420px;
  overflow: hidden;
  height: 100%;
  position: absolute;
  // height: ;
  top: 0;
  left: 0;
  bottom: 0;
  overflow-y: scroll;
  scrollbar-width: thin;
}

.micro-sitecover {
  height: 100%;
  overflow-y: scroll;
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  z-index: 99999;

  img {
    width: 100%;
    z-index: 99999;
  }

  p {
    position: fixed;
    top: 0.1rem;
    right: 0.1rem;
    width: 0.4rem;
    height: 0.25rem;
    font-size: 0.16rem;
    color: #fff;
    background: rgba(0, 0, 0, 0.5);
    line-height: 0.25rem;
    text-align: center;
    border-radius: 20px;
  }
}

.home {
  width: 100vw;
  max-width: 420px;
  overflow: hidden;
  .home-scoll {
    // 解决首页底部技术支持重叠问题
    padding-bottom: 36px;
    position: relative;

    .keep_record {
      position: absolute;
      bottom: 0;
      width: 100%;
      left: 0;
    }
  }
}

.video_box,
.video_item {
  width: 100%;
  height: 100%;
  position: relative;
}
.play_btns {
  position: absolute;
  left: 50%;
  top: 50%;
  width: 40%;
  height: 40%;
  max-width: 60px;
  max-height: 60px;
  transform: translate(-50%, -50%);
}

.home-top {
  width: 100%;
  height: 2rem;
  overflow: hidden;
  position: relative;
  
  .swipe-img {
    object-fit: cover;
  }

  .swipe-img-box {
    height: 100%;
    background-size: 100% !important;
    background-repeat: no-repeat !important;
    background-position: bottom !important;
  }

  .bg-music {
    position: absolute;
    right: 0.15rem;
    top: 0.14rem;
    width: 0.35rem;
    height: 0.35rem;

    audio {
      display: none;
    }

    .audio-control {
      width: 0.35rem;
      height: 0.35rem;
      border-radius: 50%;
      overflow: hidden;

      img {
        display: block;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        transform: translateX(0);
      }
    }
  }

  /deep/.van-swipe-item {
    overflow: hidden;
  }

  img {
    position: absolute;
    top: 0;
    left: 50%;
    transform: translateX(-50%);
  }
}

.modle-phone-box {
  position: relative;
  width: 100% !important;
  padding: 0px !important;

  .vdr {
    // touch-action: none;
    position: absolute;
    box-sizing: border-box;

    .item-box-default {
      width: 100%;
      height: 100%;
      position: relative;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      // background-repeat: no-repeat!important;
      // background-size: cover!important;
      // background-attachment: fixed!important;
      // background-repeat: no-repeat!important;
      // background-position: center!important;
    }
  }
}

.home-bottom {
  width: 96%;
  display: flex;
  // justify-content: center;
  margin: 0 auto;
  flex-wrap: wrap;
  padding: 0.1rem 0;

  .constraint_img_box {
    width: 100%;
    height: 0.3rem;
    overflow: hidden;
    margin-bottom: 0.02rem;

    img {
      width: auto;
      height: 100%;
    }
  }

  .position-icon {
    position: absolute;

    img {
      width: 100%;
      height: 100%;
    }
  }

  .position-title {
    display: inline-block;
    position: absolute;
    height: 22px;
    line-height: 22px;
    max-width: 100% !important;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    text-align: center;
    box-sizing: border-box;
  }

  .module {
    padding: 0.05rem;
  }

  .dialog-title {
    background: #f7f8fa;
    padding: 0.15rem 0;
    text-align: center;
    font-size: 0.16rem;
    font-weight: bold;
  }

  .dialog-content {
    font-size: 0.14rem;
    min-height: 1.5rem;
    max-height: 2rem;
    overflow-y: auto;
    padding: 0.1rem 0.15rem;
    line-height: 0.25rem;
    word-break: break-all;
  }

  /** 默认 */
  .item-box-default {
    width: 100%;
    height: 100%;

    p {
      font-size: 0.14rem;
      width: 100%;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
      text-align: center;
    }

    .subtitle {
      font-size: 0.12rem;
    }
  }
}

.fileModel {
  background-size: 100% 100% !important;
}

.file-phone-box {
  width: 100%;
  display: block;
  padding: 0.5rem 0.4rem 0.2rem 0.4rem !important;
}

.file-model {
  display: flex;
  width: 3rem;
  height: 0.4rem;
  background-size: 100% 100% !important;
  margin-bottom: 0.15rem;

  .img-box {
    width: 39px !important;
    height: 39px !important;
    margin: 0 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-image: url("../assets/TuPian/fileModelBg.png");
    background-size: cover;

    img {
      display: block;
      width: 24px;
      height: 24px;
    }
  }

  p {
    flex: 1;
    height: 37px;
    line-height: 35px;
    text-align: left !important;
    font-size: 0.14rem;
    width: 100%;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
}

.share-img {
  width: 20%;
  position: absolute;
  bottom: 0;
}

.hidden {
  display: none;
}

.animation_spin {
  animation: turn 3s linear infinite;
}

@keyframes turn {
  0% {
    -webkit-transform: rotate(0deg);
  }

  25% {
    -webkit-transform: rotate(90deg);
  }

  50% {
    -webkit-transform: rotate(180deg);
  }

  75% {
    -webkit-transform: rotate(270deg);
  }

  100% {
    -webkit-transform: rotate(360deg);
  }
}
</style>