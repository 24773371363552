<template>
  <!-- v-if="(authorization.wechat_status == 1 && isWeixin)" -->
    <div id="app" v-if="isShowWeixin&&is_mobile">
      <Header v-if="showUserheader && (user_header == 1)" :wx_User="wx_User" style="max-width:420px;width:100%;margin: 0 auto;z-inde:999;"></Header>
      <div v-if="musicData.music_switch == '1'" class="bg-music" :style="positionMusic" :class="{ 'top-title': showUserheader && (user_header == 1),'top-no': user_header != 1 || !showUserheader, 'zIndex': !index_home }">
        <audio ref="audio" id="musicid" :src="musicData.music_url" loop autoplay></audio>
        <div v-if="index_home" class="audio-control" @click="startPlayOrPause()">
          <img src="./assets/music/open_music.svg" class="animation_spin" alt="music" v-show="boolplay"/>
          <img src="./assets/music/close_music.svg" alt="mute" v-show="!boolplay"/>
        </div>
      </div>
      <!-- 需要缓存数据 -->
      <!-- <keep-alive>
        <router-view v-if="showHome&&$route.meta.keepAlive" :isWeixin="isWeixin" style="max-width:420px;width:100vw; overflow: hidden;position: absolute;bottom:0;left: 50%;transform: translateX(-50%);max-height: 100vh;" :style="showUserheader && (user_header == 1) ? 'top:.40rem;height:calc(100vh - 0.4rem);' : 'top:0;height:100vh;'"/>
      </keep-alive> -->
      <!-- 不需要缓存数据 && !$route.meta.keepAlive -->
      <router-view v-if="showHome" :isWeixin="isWeixin" :showUserheader="showUserheader" style="max-width:420px;width:100vw; overflow: hidden;position: absolute;bottom:0;left: 50%;transform: translateX(-50%);max-height: 100vh;" :style="{top:showUserheader && (user_header == 1) ?'0.4rem':'0',height:showUserheader && (user_header == 1)?'calc(100vh - 0.4rem)':'height:100vh', maxWidth:$route.name=='Schedule'||$route.name=='attendIntro'?'600px':'420px'}"></router-view>
    </div>
    <div id="app" v-else-if="!is_mobile">
      <router-view v-if="isRouterAlive"></router-view>
    </div>
    <div id="app" class="cipher flex-center" v-else-if="showPassword" :style="{'--themecolor':themecolors}">
        <div class="cipher-box">
            <div class="cipher-title">{{password_title}}</div>
            <el-input v-model="password" placeholder="请输入密码 "></el-input>
            <div class="login-btn" @click="passwordLogin" >登录</div>
        </div>
    </div>
    <div id="app" v-else>
      <img class="nullImg" src="./assets/wxNull.svg" alt="">
    </div>
    
 
</template>

<script>
import wxinitAPIs from "@/js/weixinSDK.js";   // 微信分享
import Header from "@/components/header";
import { mapState } from "vuex";
export default {
  provide(){
    return{
      reload:this.reload
    }
  },
  data(){
    return{
      isRouterAlive:true,//控制视图是否显示变量
      isWeixin: false,
      // boolplay: false,//音乐播放暂停
      index_home: false,//首页才显示音乐图标
      clientWidth: 0,
      isShowWeixin: true,//头部等只限微信浏览器显示
      showPassword: false,//密码显示
      password_title: '',//密码授权标题
      password: '',//密码框
      showHome: false,
      wx_User: null,
      siteUrl: '',
      authorization: {
        wechat_status: 0,
        identity_grant_status: 0,
        password_status: 0,
        audience_status: 0,
        invite_status: 0,
        mobile_status: 0,
        paid_status: 0
      },
      showUserheader: false,
      showMusic: false,
      identity_url: '',
      singlePage: false,
      musicData:'',
      is_mobile:true,
    }
  },
  watch:{
    $route(to,from){
      if(this.is_mobile){
          if(to.name == "Home"){
            this.index_home = true
          }else{
            this.index_home = false
          }
          if(to.query.singePage=='singePage'){
            this.siteUrl = to.query.site_url
            sessionStorage.setItem('siteUrl', to.query.site_url)
          }
          setTimeout(()=>{
            wxinitAPIs(sessionStorage.getItem('siteUrl'))
          },1000)
      }
    },
    siteUrl(val) {
      setTimeout(()=>{
        wxinitAPIs(sessionStorage.getItem('siteUrl'))
      },1000)
    },
  },
  components:{
    Header,
  },
  computed: {
    ...mapState(['themecolors', 'boolplay']),
    positionMusic(){
      if(this.clientWidth > 420){
        return 'left:'+ ((this.clientWidth - 420) / 2 + 380 + 'px')
      }else{
         return 'right: 0.08rem'
      }
    },
    ...mapState(["user_header", "music", "showStation", 'isShowHead'])
  },
  async created(){
    document.title = '加载中'
    this.get_loading();
    
    if(this.is_mobile){
      if(window.location.search.indexOf('singePage') > 0){
        this.showUserheader = false
        this.showMusic = false
        this.showHome = true
      }else {
        let ua = navigator.userAgent.toLowerCase()//浏览器
        this.isWeixin = ua.indexOf('micromessenger') !== -1 // 是否 在微信浏览   器内
        this.siteUrl = this.$route.params.site_url ? this.$route.params.site_url : sessionStorage.getItem('siteUrl')
        if(this.$route.name == "Home"){
          this.index_home = true
        }else{
          this.index_home = false
        }
        window.onresize = function(){
          this.$nextTick().then(()=>{
            this.clientWidth = document.body.clientWidth
          })
        }
        if(localStorage.getItem('wxmessage')) {
          this.wx_User = JSON.parse(localStorage.getItem('wxmessage'))//获取用户信息
        }
        //微信打开
        if( this.isWeixin ) {
          if (this.$cookies.isKey('microtoken')) {
            sessionStorage.setItem('formquery',1)
          }
          if (this.$cookies.isKey('microtoken') && !localStorage.getItem('wxmessage')) {//有token 没有用户信息 重新微信授权
            this.$cookies.remove('microtoken')
          }
        }
        this.getAuthorization()
      }
    }
    
  },
 async mounted(){
    this.clientWidth = document.body.clientWidth
    const that = this
    window.onresize = () => {
      return (() => {
          window.screenWidth = document.body.clientWidth
          that.clientWidth = window.screenWidth
      })()
    }
  },
  methods:{
    async get_loading(){
      this.$store.dispatch('get_custom_loading',{
        site_url:sessionStorage.getItem('siteUrl')
      }).then(res=>{
        if(res.data.code == 200){
          sessionStorage.setItem('loading_url',res.data.data.loading);
        }
      })
    },
    // 刷新视图页面
    reload(){
      this.isRouterAlive=false;
      this.$nextTick(function(){
        this.isRouterAlive=true
      })
    },
    //获取授权开关
    async getAuthorization() {
      const res_switch = await this.$store.dispatch('get_authorization_switch', this.siteUrl)
      if(res_switch.data.code === 200){
        this.authorization = res_switch.data.data
        if(this.authorization == []){
          //判断是否是微信浏览器打开  是 没有token就去微信授权 不是微信  就去调取表单接口
          if(this.isWeixin) {
            
            if(!this.$cookies.isKey('microtoken')) {
              this.weixin()
            } else {
              this.get_form_global_registration()
              this.showUserheader = true
            }
          } else {
            if(this.$cookies.isKey('userId')){
                window.localStorage.wmUserInfo = JSON.stringify({ userId: this.$cookies.get('userId'), userTag: "表单用户", projectVersion: '1.0.1', env: 'dev' })
              }else{
                 window.localStorage.wmUserInfo = JSON.stringify({ userId:new Date().getTime(), userTag: "匿名用户", projectVersion: '1.0.1', env: 'dev' })
              }
            this.get_form_global_registration()
            this.showUserheader = false
          } 
          this.showHome = true
          this.showPassword = false
          this.isShowWeixin = true
        } else {
          if(this.authorization.wechat_status == '1'){//开启微信登录
            if(!this.isWeixin) { //不是微信登录直接拦截
              this.isShowWeixin = false
              this.showPassword = false
              return
            } else {//微信登录
              this.isShowWeixin = true
              if(!this.$cookies.isKey('microtoken')){
                this.weixin()
              }else{
                 //已经授权进来直接表单
                if(this.authorization.password_status == '1'){//开启密码观看
                  this.get_password_exhibit()
                } else { //没开启密码观看
                  this.get_form_global_registration()
                  this.showPassword = false
                  this.isShowWeixin = true
                  this.showHome = true
                }
                this.showUserheader = true
              }
              
            }
          }else if (this.authorization.identity_grant_status == '1'){ //开启身份赋予
            //获取身份赋予跳转链接
            const res_identity_url = await this.$store.dispatch('get_identity_info_exhibit', {
              site_id: this.authorization.site_id
            })
            if(res_identity_url.data.code == 200) {
              this.identity_url = res_identity_url.data.data.identity_url
            }
            // //开启身份赋予 是否开启密码观看
            this.wx_User = localStorage.getItem('wxmessage')?JSON.parse(localStorage.getItem('wxmessage')):null//获取用户信息
            // if(!this.$cookies.isKey('microtoken')) {//没有身份赋予过
            if(this.$route.query.token){//判断url里面有没有token
              this.$cookies.set('microtoken', this.$route.query.token)
              const res_identity = await this.$store.dispatch('verify_identity_user_info', {
                site_id: this.authorization.site_id
              })
              if(res_identity.data.code == 200 && res_identity.data.message == '验证通过!'){
                if (localStorage.getItem('wxmessage')) {
                  localStorage.removeItem('wxmessage')
                }
                localStorage.setItem('wxmessage', JSON.stringify(res_identity.data.data))
                this.wx_User = res_identity.data.data
                if (this.authorization.password_status == 1) {//开启密码观看
                  this.get_password_exhibit()
                } else { //没开启密码观看
                  this.showPassword = false
                  this.isShowWeixin = true
                  this.showHome = true
                  this.showUserheader = true
                  this.get_form_global_registration()
                }
              } else {
                window.location.href = this.identity_url
              }
            } else {
              const res_identity = await this.$store.dispatch('verify_identity_user_info', {
                site_id: this.authorization.site_id
              })
              if(res_identity.data.code == 200 && res_identity.data.message == '验证通过!'){
                if (localStorage.getItem('wxmessage')) {
                  localStorage.removeItem('wxmessage')
                }
                localStorage.setItem('wxmessage', JSON.stringify(res_identity.data.data))
                this.wx_User = res_identity.data.data
                if (this.authorization.password_status == 1) {//开启密码观看
                  this.get_password_exhibit()
                } else { //没开启密码观看
                  this.showPassword = false
                  this.isShowWeixin = true
                  this.showHome = true
                  this.showUserheader = true
                  this.get_form_global_registration()
                }
              } else {
                window.location.href = this.identity_url
              }
            }
          } else if (this.authorization.identity_grant_status=='1' && this.authorization.password_status == '1'){
            if(this.isWeixin) {//微信登录
              this.isShowWeixin = true
              if(!this.$cookies.isKey('microtoken')){
                this.weixin()
              }else{
                this.get_password_exhibit()
              }
            } else {
              this.get_password_exhibit()
            }
          }else if(this.authorization.password_status == '1'){
            if(this.isWeixin) {//微信登录
              this.isShowWeixin = true
              if(!this.$cookies.isKey('microtoken')){
                this.weixin()
              }else{
                this.get_password_exhibit()
              }
            } else {
              this.get_password_exhibit()

            }
          } else { //pc 移动都可以打开
            if(this.isWeixin) {
              if(!this.$cookies.isKey('microtoken')) {
                this.weixin()
              } else {
                this.get_form_global_registration()
                this.showUserheader = true
              }
            } else {
              if(this.$cookies.isKey('userId')){
                window.localStorage.wmUserInfo = JSON.stringify({ userId: this.$cookies.get('userId'), userTag: "表单用户", projectVersion: '1.0.1', env: 'dev' })
              }else{
                 window.localStorage.wmUserInfo = JSON.stringify({ userId:new Date().getTime(), userTag: "匿名用户", projectVersion: '1.0.1', env: 'dev' })
              }
              this.get_form_global_registration()
              this.showUserheader = false
            }
            this.showHome = true
            this.showPassword = false
            this.isShowWeixin = true
          }
        }
      }
      this.showMusic = true
      if(res_switch.data.code == 3000) {
        this.isShowWeixin = true
        this.showUserheader = false
        this.showMusic = false
        this.showHome = true
      }
      if(res_switch.data.code == 100){
        if(this.$route.name!='Wechat'){
          // this.$toast(res_switch.data.message)
          this.isShowWeixin = true
          this.showUserheader = false
          this.showMusic = false
          this.showHome = true
          // this.$router.push('/stationnull')
        }else{
          this.isShowWeixin = true
          this.showUserheader = false
          this.showMusic = false
          this.showHome = true
        }
        
      }
    },
    //获取密码授权标题
    async get_password_exhibit() {
      const res = await this.$store.dispatch('get_password_exhibit', {
        site_id: this.authorization.site_id
      })
      if(res.data.code == 200) {
        this.password_title = res.data.data.title
        this.showPassword = true
        this.isShowWeixin = false
      }
      if(res.data.code == 20002) {//用户已验证
        this.showPassword = false
        this.isShowWeixin = true
        this.showHome = true
        this.get_form_global_registration()
        if(this.isWeixin && this.$cookies.get('microtoken') && localStorage.getItem('wxmessage')) {
            this.showUserheader = true
        }
      }
    },
    //密码授权验证
    async passwordLogin() {
      if(this.password){
        const res = await this.$store.dispatch('password_verify', {
          site_id: this.authorization.site_id,
          password: this.password
        })
        if(res.data.code == 200) {
          this.get_form_global_registration()
          if(this.isWeixin) {
           if(this.$cookies.get('microtoken') && localStorage.getItem('wxmessage')){
            this.showUserheader = true
           }
          }else{
            this.showUserheader=false
          }
           this.isShowWeixin = true
          this.showPassword = false
          this.showHome = true
          if((!this.isWeixin && !this.authorization.identity_grant_status) && !this.authorization.identity_grant_status){
            this.$cookies.set('microtoken', res.data.data.token)
            localStorage.setItem('wxmessage', JSON.stringify(res.data.data.user))
            this.wx_User = res.data.data.user
          }
        }
        else{
            this.$toast(res.data.message);
        }
      } else {
        this.$toast('请输入密码');
      }
    },
    //获取音乐开关
    async getMusic() {
      const res = await this.$store.dispatch("front_desk_home_show", {
        site_url: this.siteUrl,
      })
      if(res.data.code == 200){
        this.musicData=res.data.data.page_style.background_music
        sessionStorage.setItem('technical_support', res.data.data.page_style.technical_support)
        this.$store.commit("changeHeaderMusic", true)
        if(res.data.data.page_style.user_header){
          sessionStorage.setItem('user_header',res.data.data.page_style.user_header)
          this.$store.commit('changeUserHeader', true)
        }else {
          sessionStorage.setItem('user_header',res.data.data.page_style.user_header)
          this.$store.commit('changeUserHeader', false)
        }
        sessionStorage.setItem('wei-title', res.data.data.title)
      }else{
        this.$store.commit("changeHeaderMusic", false)
      }
      document.addEventListener('WeixinJSBridgeReady', function onBridgeReady() { WeixinJSBridge.call('hideToolbar') });
      if(res.data.data.page_style.background_music.music_switch == 1){
        this.bgm = this.BGMAutoPlayMgr(res.data.data.page_style.background_music.music_url);
      }
    },
    BGMAutoPlayMgr(url) {
          this.audioContext = new (window.AudioContext || window.webkitAudioContext || window.mozAudioContext)();
          this.sourceNode = null;
          this.buffer = null;
          this.isPlayingBGM = false;
          let loadAndAutoPlay = (audioUrl) => {
              const audioContext = this.audioContext;
              const xhr = new XMLHttpRequest();
              xhr.open('GET', audioUrl, true);
              xhr.responseType = 'arraybuffer';
              xhr.onreadystatechange = () => {
                  if (xhr.status < 400 && xhr.status >= 200 && xhr.readyState === 4) {
                      audioContext.decodeAudioData(xhr.response, buffer => {
                          this.buffer = buffer;
                          if(this.$refs.audio.paused){
                             this.$store.commit('changeMusicImg', false)
                          }else{
                             this.$store.commit('changeMusicImg', true)
                          }
                      });
                  }
              }
              xhr.send();
          }
          loadAndAutoPlay(url);
          loadAndAutoPlay = null;
    },

    //音乐播放
    getAudioPlay(){
      setTimeout(() => {
        let aAudio = document.getElementById("musicid");
        aAudio.play();
        // this.boolplay = true;
        this.$store.commit('changeMusicImg', true)
      }, 800);
      this.touchplay = false;
    },
    startPlayOrPause() {
      this.$nextTick().then(() => {
        let musicdom = document.getElementById("musicid");
        if (musicdom.paused) {
          musicdom.play();
          this.$store.commit('changeMusicImg', true)
        } else {
          musicdom.pause();
          this.$store.commit('changeMusicImg', false)
        }
        // if (this.boolplay) {
        //   musicdom.pause();
        //   // this.boolplay = false;
        //   this.$store.commit('changeMusicImg', false)
        // } else {
        //   musicdom.play();
        //   // this.boolplay = true;
        //   this.$store.commit('changeMusicImg', true)
        // }
      });
    },
    play() {
      this.$refs.audio.play();
    },
    pause() {
      this.$refs.audio.pause();
    },
    //微信授权
    weixin(){
      // let isURL = window.location.href.indexOf('code=') === -1 // 判断URL中是否有code码 没有code 返回true 获取微信授权地址
      if (this.isWeixin && !sessionStorage.getItem('microcode')) {
          this.getLogin()
      }
      // else{
      //   wxinitAPIs(sessionStorage.getItem('siteUrl'))
      // }
      // 重定向回来
      if (sessionStorage.getItem('microcode')) {
        //授权成功获取微信信息
        this.$store.dispatch("official_account_call_back",{
          code: sessionStorage.getItem('microcode'),
          site_url: sessionStorage.getItem("siteUrl") || this.$route.params.site_url || ''
        }).then(async res=>{
          if(res.data.code == 200){
            this.wx_User = res.data.data.user_info
            let wxUser = JSON.stringify(res.data.data.user_info)
            if( localStorage.getItem('wxmessage') ){
              localStorage.removeItem('wxmessage')
            }
            localStorage.setItem('wxmessage', wxUser)
            sessionStorage.removeItem('microcode')
            this.$cookies.set('microtoken', res.data.data.token)
            // window.localStorage.wmUserInfo = JSON.stringify({ userId: res.data.data.user_info.id, userTag: res.data.data.user_info.nickname, projectVersion: '1.0.1' })
            window.localStorage.wmUserInfo = JSON.stringify({ userId: res.data.data.user_info.id, userTag: "微信用户", projectVersion: '1.0.1', env: 'dev' })
            this.token = res.data.data.token;
            if(this.authorization.password_status == '1') {
              this.get_password_exhibit()
            } else{
              this.showPassword = false
              this.isShowWeixin = true
              this.showHome = true
              this.showUserheader = true
            }
            this.get_form_global_registration();
            wxinitAPIs(window.sessionStorage.getItem('siteUrl'))
          }else if(res.data.code == 303){
            window.location.href = res.data.redirectUrl
          }
        })
      }
    },
    //去微信授权页面
    getLogin() {
       this.$store.dispatch('official_account_login', {
          site_url: this.siteUrl
        }).then(res=>{
          if(res.data.code == 303){
            window.location.href = res.data.redirect_url
          }
        })
    },
    /** 全局表单展示 */
    get_form_global_registration(){
        this.getMusic()
        this.$store.dispatch('get_form_global_registration',{
            site_url: sessionStorage.getItem("siteUrl")
        }).then(res=>{
            sessionStorage.setItem('formquery',1)
            if(res.data.code == 200 ){
              if(res.data.data.global_sign_up_form_login != 1){
                // this.$route.name == 'Loginindex' /login页面
                this.$route.name == 'Loginindex' && this.$router.replace(`/event/${window.sessionStorage.getItem("siteUrl")}`)
                window.sessionStorage.setItem('overall',0)
                return
              }
              if(res.data.data.is_register == 0 && res.data.data.global_sign_up_form_login == 1 && !this.$cookies.get("formregister-" + window.sessionStorage.getItem('siteUrl'))){
                window.sessionStorage.setItem('overall',1)
                // this.$router.replace({name:'Loginindex'});
              }else{
                window.sessionStorage.setItem('overall',0)
              }
            }
        }).catch(err=> window.sessionStorage.setItem('formquery',1))
    },
  }
}
</script>
<style lang="less">
body,html{ touch-action: none; -webkit-overflow-scrolling: auto;overflow: hidden;} 
@Color:var(--themecolor);
#app {
   height: 100vh;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #2c3e50;
  // font-family: "PingFang";
  font-family: PingFangSC-Regular, sans-serif, Avenir, Helvetica, Arial;
  scrollbar-width: none;
  .nullImg{
    position: fixed;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    width: 2.13rem;
    height: 2.7rem;
  }
  .top-title{
    top: 0.48rem
  }
  .top-no{
    top: 0.1rem;
  }
  .zIndex{
    z-index: 0!important;
  }
  .bg-music {
    position: absolute;
    width: 0.30rem;
    height: 0.30rem;
    z-index: 200;
    audio {
      display: none;
    }
    .audio-control {
      width: 0.3rem;
      height: 0.3rem;
      border-radius: 50%;
      overflow: hidden;
      img {
        display: block;
        width: 100%;
        height: 100%;
        opacity: .8!important;
        top: 0;
        left: 0;
        transform: translateX(0);
      }
    }
  }
}
.flex-center{
    display: flex;
    justify-content: center;
    align-items: center;
}
.cipher{
  width: 100%;
  height: 100%;
  max-width: 420px;
  margin: 0 auto;
  background:url("./assets/empowerbg.jpg") 100% 100%;
    .cipher-box{
        width: 300px;
        height: 300px;
        box-shadow: 0 5px 12px 1px rgba(0, 0, 0, 0.25);
        border-radius: 10px;
        padding: 30px;
        box-sizing: border-box;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        background: #fff;
        .cipher-title{
            width: 100%;
            display: -webkit-box;
            -webkit-box-orient: vertical;
            -webkit-line-clamp: 2;
            overflow: hidden;
            font-size: 16px;
            font-weight: 700;
            // height: 50px;
            line-height: 25px;
            text-align: center;
        }
        .el-input{
          margin: 26px 0 34px;
          input:focus{
            border-color: @Color;
          }
        }
        .login-btn{
            width: 100%;
            height: 36px;
            line-height: 36px;
            text-align: center;
            font-size: 14px;
            color: #fff;
            cursor: pointer;
            // background-color: @Color;
            border-radius: 20px;
            background-color: @Color;
        }
    }
}
.animation_spin {
  animation: turn 3s linear infinite;
}
@keyframes turn {
  0% {
    -webkit-transform: rotate(0deg);
  }
  25% {
    -webkit-transform: rotate(90deg);
  }
  50% {
    -webkit-transform: rotate(180deg);
  }
  75% {
    -webkit-transform: rotate(270deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
  }
}
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
  width: 100%;
  height: 100vh;
}

 /* 设置滚动条 */
::-webkit-scrollbar{
    width:0;
}

// element-ui 图片预览
/deep/ .el-image-viewer__wrapper{
  .el-image-viewer__mask{
    opacity: 0.8;
  }
}
</style>
