<template>
  <!-- 视频 -->
  <div class="mbileBox" id="scroll">
    <div style="overflow: hidden;height: 95%">
      <div class="videoplayBox">
        <div style="height:2.1rem" v-if="coverimg">
          <img v-if="coverImgUrl == ''" style="width: 100%; height:2.1rem"
            src="https://images.qingtv.com/bonwey-oa/aa42363b13b6ed29aa37ddacdf8eafbd.png?imageView2/0/interlace/1/q/75|imageslim" />
          <img v-else style="width: 100%; height:  2.1rem" :src="coverImgUrl" />
        </div>
        <div v-else id="videoBox"></div>
      </div>
      <div class="tabBox">
        <div class="main">
          <div
            style="width: 100%;  margin: 0 auto;  background: #fff;  height: 45px;  box-shadow: 0px 3px 6px rgba(64, 64, 64, 0.16);">
            <ul>
              <li v-for="(item, index) in tabsName" :key="index" @click.stop="tabsSwitch(item.index, index)"
                :style="item.isActive ? `border-bottom:2px solid ${themecolors}` : ''">
                <span class="tab-link" :class="{ active: item.isActive }">{{ item.name }}</span>
              </li>
            </ul>
          </div>
        </div>
        <div class="activeTime">
          <div class="secondBox" v-if="isfirst">
            <div class="secondCon">
              <van-loading :color="themecolors" :text-color="themecolors" v-if="Loading" />
              <van-empty :image="require('@/assets/null.svg')" v-if="listData.length == 0" />
              <div v-else class="conBox" v-for="(item, index) in listData" :key="index">
                <div class="center" @click="getvideoid(item, index)">
                  <div class="center_img">
                    <img :src="item.cover_url" alt="" />
                  </div>
                  <div class="centime" :style="{ 'background': videoindex === index ? `${themecolor}` : '' }">
                    {{ item.total_duration }}</div>
                  <div class="conright">
                    <p class="conright-p" :style="{ 'color': videoindex === index ? themecolors : '' }">
                      {{ item.video_title }}</p>
                    <div class="centertime flex">
                      <div class="listcon flex">
                        <img src="@/assets/otherTU/time.png" />
                        <p>{{ item.created_at }}</p>
                      </div>
                      <div style="justify-content: flex-end" class="listcon flex">
                        <img src="@/assets/otherTU/play.svg" />
                        <p>{{ item.play_volume }}</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="firstBox" v-else>
            <van-empty :image="require('@/assets/null.svg')" v-if="listData.length == 0 || videointroduction == null" />
            <div v-else class="firsttitle" @click.stop="reductionheight()">
              <span v-html="videointroduction"></span>
            </div>
          </div>
        </div>
      </div>
    </div>
    <Support></Support>
  </div>
</template>
<script>
import HlsJsPlayer from "xgplayer-hls.js"; //西瓜播放器M3U8
import Player from "xgplayer"; //西瓜播放器 MP4
import "@/views/video/xgplayer/.xgplayer/skin/index.js";
import Support from "@/components/support";
import { mapState } from "vuex";
export default {
  components: {
    Support,
  },
  props: ['app'],
  data() {
    return {
      videoindex: null, //当前选择的视频添加高亮
      Loading: false,
      contentheight: false,
      player: "", //m3u8
      videourl: "", //播放地址
      coverimg: true, //封面图
      coverImgUrl: "", //封面图路径
      itemtitle: "", //标题
      videointroduction: "",
      ispeople: false, //人数默认不显示
      isActive: true,
      isActives: true,
      isfirst: true, //视频简介
      tabsName: [
        {
          name: "视频列表",
          isActive: true,
          index: 0,
        },
        {
          name: "视频简介",
          type_id: "2",
          index: 1,
        },
      ],
      listData: [],
      params: {},
      latetime: false, //视频列表默认可以点击
      oldid: null,
      show_grouping: "", //云展厅传入分类
    };
  },
  created() {
    if (this.device() == 0) {
      this.playsinline = false;
    } else {
      this.playsinline = true;
    }
    let obj = {
      module_id: this.app.random_number,
      page_id: this.app.page_id,
      site_id: this.site_id,
    }
    this.params = obj
  },
  mounted() {
    if (!this.app.exhibition_skip) {
      this.get_video_list();
    } else {
      this.showroom_video_list();
    }
  },
  computed: {
    ...mapState(["themecolors", "themecolor", "site_id"]),
  },
  methods: {
    //切换栏的方法
    tabsSwitch(index) {
      var _this = this;
      _this.tabsName.filter((item) => {
        item.isActive = false; //不是当前点击的不显示
        if (item.index == index) {
          if (index == 0) {
            this.isfirst = true;
          }
          if (index == 1) {
            this.isfirst = false;
          }
          item.isActive = true; //是当前点击的显示
        }
      });
    },
    // 云展厅展示视频分类
    showroom_video_list() {
      this.$store
        .dispatch("showroom_video_list", {
          site_id: this.params.site_id,
          show_grouping: this.app.show_grouping,
        })
        .then((res) => {
          if (res.data.code === 200) {
            this.coverImgUrl = res.data.data.list_kv;
            this.listData = res.data.data.data.module_content;
            if (this.listData.length != 0) {
              // this.coverImgUrl = res.data.data.data.module_content[0].cover_url
              this.itemtitle = res.data.data.data.module_content[0].video_title;
              this.videointroduction =
                res.data.data.data.module_content[0].video_introduction;
            }
          }
        });
    },
    //接口
    get_video_list() {
      this.loading = true;
      this.$store
        .dispatch("get_video_list", {
          site_id: this.params.site_id,
          module_id: this.params.module_id,
          status: 0,
        })
        .then((res) => {
          this.loading = false;
          if (res.data.code == 200) {
            this.listData = res.data.data.data.module_content;
            this.coverImgUrl = res.data.data.list_kv;
            if (this.listData.length != 0) {
              // this.coverImgUrl = res.data.data.data.module_content[0].cover_url
              this.itemtitle = res.data.data.data.module_content[0].video_title;
              this.videointroduction =
                res.data.data.data.module_content[0].video_introduction;
            }
          }
        });
    },
    //视频链接跳转
    getvideoid(item, index) {
      this.videoindex = index;
      if (this.latetime || this.oldid == item.id) {
        return;
      }
      this.latetime = true;
      if (this.player) {
        if (this.player.hasStart) {
          this.player.destroy(true);
        }
      }
      let video = item.video_url;
      let ismp4 = video.substring(video.length - 4);
      this.videourl = item.video_url;
      this.coverimgs = item.cover_url;
      this.videointroduction = item.video_introduction;
      if (ismp4 === ".mp4") {
        this.coverimg = false;
        this.oldid = item.id;
        setTimeout(() => {
          this.Isplayer();
        });
      } else {
        this.coverimg = false;
        this.oldid = item.id;
        setTimeout(() => {
          this.liveBroadcast();
        });
      }
      this.$store.dispatch("get_view_count", { id: item.id }).then((res) => {
        if (res.data.code == 200) {
          item.play_volume = parseInt(item.play_volume) + 1;
        }
      });
    },
    //M3U8
    liveBroadcast(obj) {
      this.player = new HlsJsPlayer({
        id: "videoBox",
        url: this.videourl,
        // poster:this.coverimgs,
        isLive: false, //是否直播
        fluid: true, //流式布局
        autoplay: true,
        playsinline: this.playsinline,
        // download: true,
        lang: "zh-cn",
        // loop: true,
        closeVideoClick: true,
      });
      this.$nextTick(() => {
        this.player.on("ended", () => {
          if (this.listData.length > this.videoindex + 1) {
            this.getvideoid(this.listData[this.videoindex + 1], this.videoindex + 1)
          } else {
            this.getvideoid(this.listData[0], 0)
          }
        });

        this.player.on("play", () => {
          this.$nextTick().then(() => {
            if (document.getElementById("musicid")) {
              document.getElementById("musicid").pause();
              this.$store.commit("changeMusicImg", false);
            }
          });
          this.player.on("controlShow", () => {
            this.ispeople = false;
          });
          this.player.on("controlHide", () => {
            this.ispeople = true;
          });
          this.latetime = false;
          document.getElementsByClassName(".xgplayer-icon-large").style =
            "none";
          document.getElementsByClassName(".xgplayer-icon-play").style = "none";
          document.getElementsByClassName(".xgplayer-icon-pause").style =
            "none";
          document.querySelector(".xgplayer-volume").style.display = "none";
          document.querySelector(".xgplayer-fullscreen").style.display = "none";
        });
      });
    },
    //MP4
    Isplayer(obj) {
      this.player = new Player({
        id: "videoBox",
        url: this.videourl,
        // poster:this.coverimgs,
        isLive: true, //是否直播
        fluid: true, //流式布局
        autoplay: true,
        playsinline: this.playsinline,
        // download: true,
        lang: "zh-cn",
        // loop: true,
        closeVideoClick: true,
      });
      this.$nextTick(() => {
        this.player.on("ended", () => {
          if (this.listData.length > this.videoindex + 1) {
            this.getvideoid(this.listData[this.videoindex + 1], this.videoindex + 1)
          } else {
            this.getvideoid(this.listData[0], 0)
          }
        });
        this.player.on("play", () => {
          this.$nextTick().then(() => {
            if (document.getElementById("musicid")) {
              document.getElementById("musicid").pause();
              this.$store.commit("changeMusicImg", false);
            }
          });
          this.player.on("controlShow", () => {
            this.ispeople = false;
          });
          this.player.on("controlHide", () => {
            this.ispeople = true;
          });
          this.latetime = false;
          $(".xgplayer-skin-undefined video").css({ objectFit: "cover", });
          document.getElementsByClassName(".xgplayer-icon-large").style =
            "none";
          document.getElementsByClassName(".xgplayer-icon-play").style = "none";
          document.getElementsByClassName(".xgplayer-icon-pause").style =
            "none";
          document.querySelector(".xgplayer-volume").style.display = "none";
          document.querySelector(".xgplayer-fullscreen").style.display = "none";
        });
      });
    },
    //判断手机类型
    device() {
      var u = navigator.userAgent;
      if (u.indexOf("Android") > -1 || u.indexOf("Linux") > -1) {
        //安卓手机
        return 0;
      } else if (u.indexOf("iPhone") > -1) {
        //苹果手机
        return 1;
      } else if (u.indexOf("Windows Phone") > -1) {
        //winphone手机
        return 2;
      }
    },
    reductionheight() {
      this.contentheight = !this.contentheight;
    },
  },
};
</script>

<style lang="less" scoped>
.flex {
  display: flex;
  align-items: center;
}

/deep/.van-empty,
/deep/.van-loading {
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
}

/deep/.van-empty {
  width: 100%;

  .van-empty__image {
    width: 300px;
    height: 300px;
  }
}

/deep/.van-loading {
  top: 60%;
}

.mbileBox {
  width: 100%;
  height: 100%;
  max-width: 420px;
  background: #ffffff;
  // overflow-y: scroll;
  box-sizing: border-box;

  .coverimg {
    width: 100%;
    height: 230px;

    img {
      width: 100%;
      height: 100%;
    }
  }

  video {
    object-fit: contain !important;
  }

  .videoplayBox {
    width: 100%;
    height: 2.1rem;
    box-shadow: 0px 2px 8px rgba(102, 102, 102, 0.3);
    object-fit: contain;
  }

  #videoBox {
    width: 100% !important;
    height: 2.1rem !important;
    object-fit: contain;
  }

  .livestatus {
    position: absolute;
    width: 50px;
    height: 25px;
    background-color: rgba(0, 0, 0, 0.2);
    border-radius: 35px;
    top: 10px;
    left: 10px;

    span {
      color: #ef5350;
      text-align: center;
      line-height: 25px;
      display: block;
      font-size: 14px;
    }
  }

  .tabBox {
    width: 100%;
    height: calc(100% - 2.1rem);

    .main {
      height: 50px;
      background: #fff;

      ul {
        width: 90%;
        margin: 0 auto;
        display: flex;
        line-height: 40px;
        height: 100%;
        z-index: 99;

        li {
          height: 100%;
          padding-top: 6px;
          margin: 0 20px;
          text-align: center;
          line-height: 30px;
          color: #ffffff;
        }
      }

      ul .tab-link {
        display: block;
        text-align: center;
        color: #666;
        font-size: 14px;
        line-height: 30rpx;
        overflow: hidden;
      }

      ul .tab-img {
        display: none;
        height: 8px;
        // margin-left: 20px;
        margin: 0 auto;
      }

      ul .active {
        display: block;
        color: #000;
        font-weight: bold;
      }
    }

    .activeTime::-webkit-scrollbar {
      display: none;
    }

    .activeTime {
      width: 100%;
      overflow: scroll;
      height: calc(100% - 50px);

      .firstBox {
        width: 100%;
        height: 100%;
        margin-top: 10px;

        .firsttitle {
          width: 100%;
          background: #fff;
          padding: 6px;
          margin-top: 10px;

          span {
            width: 95%;
            margin: 0 auto;
            line-height: 20px;
            font-size: 14px;
            display: block;
            word-break: break-all;
          }

          .conHeight {
            height: auto;
            width: 94%;
          }
        }

        .imgbox {
          width: 100%;
          height: auto;
          background: #fff;
          margin-top: 20px;
          padding: 20px;

          img {
            width: 95%;
            height: auto;
            margin: 0 auto;
            display: block;
          }
        }
      }

      .secondBox {
        width: 100%;
        height: 100%;
        background: #fff;
        margin-top: 10px;

        .secondCon {
          background: #fff;

          .conBox {
            display: flex;
            width: 98%;
            background: #ffffff;
            margin: 0 auto;

            .center {
              width: 100%;
              height: 80px;
              display: flex;
              justify-content: space-around;
              margin-top: 10px;
              position: relative;

              .center_img {
                width: 120px;
                height: 70px;
                border-radius: 3px;
                overflow: hidden;
                position: relative;

                .centime_up {
                  left: 50%;
                  top: 50%;
                  position: absolute;
                  transform: translate(-50%, -80%);
                  font-size: 12px;
                }

                img {
                  width: 100%;
                  height: 100%;
                  object-fit: cover;
                  position: absolute;
                  top: 0;
                  left: 0;
                }
              }

              .centime {
                width: 60px;
                height: 20px;
                position: absolute;
                background: rgba(0, 0, 0, 0.7);
                color: #fff;
                border-radius: 30px;
                font-size: 12px;
                left: 62px;
                text-align: center;
                line-height: 20px;
                margin-top: 45px;
              }

              .conright {
                width: calc(100% - 150px);
                font-size: 14px;

                p:first-child {
                  font-weight: 400;
                }

                .conright-p {
                  margin-bottom: 10px;
                }

                p {
                  color: #333333;
                  // line-height: 25px;
                  height: 40px;
                  line-height: 20px;
                  display: -webkit-box;
                  overflow: hidden;
                  -webkit-box-orient: vertical;
                  -webkit-line-clamp: 2;
                  word-wrap: break-word;
                  white-space: normal;
                  word-break: break-all;
                }

                .centertime {
                  color: #969799;
                  font-size: 12px;
                  height: 14px;
                  display: flex;

                  .listcon {
                    width: 50%;
                    height: 14px;

                    img {
                      width: 12px;
                      height: 12px;
                    }

                    p {
                      min-width: 40px;
                      height: 14px;
                      line-height: 14px;
                      display: inline-block;
                      margin-left: 5px;
                      color: #969799;
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}
</style>
