import { render, staticRenderFns } from "./wechatqrcode.vue?vue&type=template&id=007f2b23&scoped=true&"
import script from "./wechatqrcode.vue?vue&type=script&lang=js&"
export * from "./wechatqrcode.vue?vue&type=script&lang=js&"
import style0 from "./wechatqrcode.vue?vue&type=style&index=0&id=007f2b23&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "007f2b23",
  null
  
)

export default component.exports