<template>
    <div class="header" @click="cancel($event)" :style="{height:showSide ? '100%' : 'auto'}">
        <div class="nav flex between">
            <!-- <i v-if="!showSide" class="el-icon-s-unfold" style="width:0.3rem; height: 0.3rem; font-size:.3rem" @click.stop="showSide =! showSide"></i>
            <i v-else class="el-icon-s-fold" style="width:0.3rem; height: 0.3rem; font-size:.3rem" @click.stop="showSide =! showSide"></i> -->
            <div class="hamburger_header" style="padding: 0 0px;" @click.stop="showSide =! showSide">
                <svg
                    :class="{'is-active':showSide}"
                    class="hamburger"
                    viewBox="0 0 1024 1024"
                    xmlns="http://www.w3.org/2000/svg"
                >
                    <path d="M408 442h480c4.4 0 8-3.6 8-8v-56c0-4.4-3.6-8-8-8H408c-4.4 0-8 3.6-8 8v56c0 4.4 3.6 8 8 8zm-8 204c0 4.4 3.6 8 8 8h480c4.4 0 8-3.6 8-8v-56c0-4.4-3.6-8-8-8H408c-4.4 0-8 3.6-8 8v56zm504-486H120c-4.4 0-8 3.6-8 8v56c0 4.4 3.6 8 8 8h784c4.4 0 8-3.6 8-8v-56c0-4.4-3.6-8-8-8zm0 632H120c-4.4 0-8 3.6-8 8v56c0 4.4 3.6 8 8 8h784c4.4 0 8-3.6 8-8v-56c0-4.4-3.6-8-8-8zM142.4 642.1L298.7 519a8.84 8.84 0 0 0 0-13.9L142.4 381.9c-5.8-4.6-14.4-.5-14.4 6.9v246.3a8.9 8.9 0 0 0 14.4 7z" />
                </svg>
            </div>
            <div class="flex" style="font-size:.18rem;">
              <van-icon :name="station_collect == 3 ? 'star' :'star-o'" @click="attention" :style="{marginRight:'.2rem',color: station_collect == 3 ? '#F5AB16' : ''}"/>
              <div class="avatar">
                  <img v-if="wx_User" :src="wx_User.avatar_url" alt="">
                  <img v-else src="../assets/avatar.svg" alt="">
              </div>
            </div>
        </div>
        <div class="sidebar_left">
            <!-- <transition name="sideLeftfade"> -->
                <div class="side-bar" id="side-bar" :style="{'left':(showSide?'':'-2rem')}">
                    <div class="avatar">
                        <img v-if="wx_User" :src="wx_User.avatar_url" alt="">
                        <img v-else src="../assets/avatar.svg" alt="">
                        <p v-if="!wx_User">您未登录，请 <span style="color:#333;" @click="logonFn">登录</span></p>
                        <p v-else>{{ wx_User ? wx_User.nickname : '' }}</p>
                    </div>
                    <div class="edit flex between">
                        <!-- <p @click="userInfo">个人信息</p>
                        <p>编辑信息</p> -->
                    </div>
                    <div class="side-bototm">
                        <div @click="history()">
                            <img src="../assets/icon/fangwen.svg" alt="">
                            <p><span>查看</span>历史</p>
                        </div>
                        <div @click="Collection()">
                            <img src="../assets/icon/shoucang.svg" alt="">
                            <p><span>我的</span>收藏</p>
                        </div>
                        <div @click="follow()">
                            <img src="../assets/icon/guanzhu.svg" alt="">
                            <p><span>我的</span>关注</p>
                        </div>
                    </div>
                </div>
            <!-- </transition> -->
            <div class="side-bar_right" v-if="showSide"></div>
        </div>
    </div>
</template>

<script>
export default{
    data(){
        return{
            showSide: false,
            isCollect: false,
            // wx_User: null, //微信授权信息
            isLogin: false,
            station_collect: '',
            isActive:false
        }
    },
    props: {
        wx_User: {
            typeof: Object,
            default: false
        }
    },
    created(){
        // this.wx_User = JSON.parse(localStorage.getItem('wxmessage'))
        
    },
    watch:{
        $route(to,from){
            this.getStatus()
            setTimeout(()=>{
              this.station_collect = localStorage.getItem('station_collect')
            },1000)
        }
    },
    mounted(){
        this.isLogin = localStorage.getItem('wxmessage') ? false : true
        if(this.isLogin){
            this.$store.dispatch('get_site_record_collection_status', {
              site_id: this.$cookies.get('site_id'),
              site_url: sessionStorage.getItem('siteUrl')
            }).then(res=>{
              if(localStorage.getItem("station_collect")){
                localStorage.removeItem('station_collect')
              }
              this.station_collect = res.data.data.collection_status
              localStorage.setItem('station_collect', res.data.data.collection_status)
            })
        }
        setTimeout(() => {
            this.station_collect = localStorage.getItem('station_collect')
        }, 1000);
    },
    methods:{
        getStatus(){
            if(this.isLogin){
                this.$store.dispatch('get_site_record_collection_status', {
                  site_id: this.$cookies.get('site_id'),
                  site_url: sessionStorage.getItem('siteUrl')
                }).then(res=>{
                  if(localStorage.getItem("station_collect")){
                    localStorage.removeItem('station_collect')
                  }
                  this.station_collect = res.data.data.collection_status
                  localStorage.setItem('station_collect', res.data.data.collection_status)
                })
            }
        },
        logonFn(){
            if(!this.$cookies.isKey('microtoken')){
                this.$toast('请用微信浏览器打开！')
                return
            }
        },
        cancel(event) {
            if (this.showSide) {
                var vt = document.getElementById("side-bar");
                if (!vt.contains(event.target))
                this.showSide = false;
            }
        },
        userInfo(){
            if(!this.$cookies.isKey('microtoken')){
                this.$toast('请用微信浏览器打开！')
                return
            }
            setTimeout(()=>{
                this.$router.push({name:'userinfo'})
            },450)
        },
        Collection(){
            if(!this.$cookies.isKey('microtoken')){
                this.$toast('请用微信浏览器打开！')
                return
            }
            this.showSide = !this.showSide
            this.$router.push({name:"Collection"})
        },
        history(){
            if(!this.$cookies.isKey('microtoken')){
                this.$toast('请用微信浏览器打开！')
                return
            }
            this.showSide = !this.showSide
            setTimeout(()=>{
                this.$router.push({name:"history"})
            },450)
        },
        follow(){
            if(!this.$cookies.isKey('microtoken')){
                this.$toast('请用微信浏览器打开！')
                return
            }
            this.showSide = !this.showSide
            setTimeout(()=>{
                this.$router.push({name:"follow"})
            },450)
            
        },
        async attention(){
            if(!this.$cookies.isKey('microtoken')){
                this.$toast('请用微信浏览器打开！')
                return
            }
            let res
            if(this.station_collect == 3){//取消收藏
                res = await this.$store.dispatch('unfavorite_the_site',{
                    site_id: this.$cookies.get('site_id'),
                    site_url: sessionStorage.getItem('siteUrl')
                })
            }else{//收藏
                res = await this.$store.dispatch('add_collection_record',{
                    site_id: this.$cookies.get('site_id'),
                    site_url: sessionStorage.getItem('siteUrl')
                })
            }
            if(res.data.code == 200){
                localStorage.removeItem('station_collect')
                if(this.station_collect == 3){
                    localStorage.setItem("station_collect",0)
                    this.station_collect = 0
                }else{
                    localStorage.setItem("station_collect",3)
                    this.station_collect = 3
                }
                this.$toast(res.data.message)
            }else{
                this.$toast(res.data.message)
            }
        }
    }
}
</script>

<style lang='less' scoped>
/deep/ .hamburger_header{
    width: 0.22rem;
    height: 0.22rem;
    position: relative;
    .is-active {
      transform: rotate(180deg);
    }
    .hamburger{
        position: absolute;
    }
}
.flex{
    display: flex;
    align-items: center;
}
 @keyframes sideLeftfadeIn {
    0% {
        opacity: 1;
        left: -2.2rem;
    }
    100% {
        opacity: 1;
        left: 0;
    }
}
.sideLeftfade-enter-active {
    opacity: 1;
    animation: sideLeftfadeIn .5s;
}
.sideLeftfade-leave-active {
    opacity: 1;
    animation: sideLeftfadeIn .5s reverse;
}
.between{
    justify-content: space-between;
}
.header{
    max-width:420px!important;
    margin: 0 auto;
    width: 100%;
    // height: 100%;
    overflow: hidden;
    max-width: 3.75rem!important;
    .nav{
        // position: absolute;
        // top: 0;
        // left: 0;
        width: 100%;
        max-width: 750px;
        margin: 0 auto;
        padding:  0 .15rem;
        height: .40rem;
        font-size: .28rem;
        background: #fff;
        box-shadow: 0px 5px 8px #EEEEEE;
        z-index: 999;
        border-bottom: 2px solid rgba(204, 204, 204, 0.1);
        box-sizing: border-box;
        .avatar{
            width: .3rem;
            height: .3rem;
            border-radius: 50%;
            overflow: hidden;
            img{
                // width: 100%;
                height: 100%;
            }
        }
    }
    .sidebar_left{
        // width: 100%;
        // height: 100%;
        position: relative;
        // top: 0.44rem;
        overflow-x: hidden!important;
        overflow-y: hidden;
        height: calc(100vh - 0.40rem)!important;
        max-height: 812px;
        display: flex;
        justify-content: flex-start;
    }
    .side-bar_right{
        position: relative;
        width: calc(100% - 1.8rem);
        top: 0;
        bottom: 0;
        // height: calc(100%-0.44rem)
        opacity: 0;
        z-index: 100;
    }
    .side-bar{
        // position: fixed;
        position: relative;
        // top:.44rem;
        bottom: 0;
        width: 1.8rem;
        // height: 99.51vh;
        box-shadow: 4px 1px 4px rgba(0, 0, 0, 0.1);
        // z-index: 99;
        background: #fff;
        
        // box-shadow: 0px -10px rgba(0, 0, 0, 0.16);
        text-align: center;
        z-index: 99;
        left: 0;
        transition: all .5s;
        .avatar {
            line-height: .24rem;
            img{
                width: .8rem;
                height: 0.8rem;
                border-radius: 0.4rem;
                overflow: hidden;
                margin: .3rem 0 .15rem;
            }
            p{
                color: #ADB5BA;
                font-size: .14rem;
            }
        }
        .edit{
            margin: .15rem 0 .35rem;
            padding: .15rem .2rem;
            border-bottom: 1px solid #E5E5E5;
            font-size: .14rem;
        }
        .side-bototm{
            font-size: .16rem;
            color: #575F64;
            div{
                display: flex;
                justify-content: flex-start;
                align-items: center;
                height: .5rem;
                img{
                    display: block;
                    width: 0.15rem;
                    height: 0.15rem;
                    // margin: 0rem 0.12rem 0.1rem .3rem;
                    margin:0 .12rem 0 .40rem;
                    // margin-right: .12rem;
                    box-sizing: border-box;
                }
            }
            p{
                margin: 0;
                height: 0.5rem;

            }
            span{
                display: inline-block;
                height: 0.5rem;
                line-height: .5rem;
                box-sizing: border-box;
                border-bottom: 1px solid #E5E5E5;
            }
        }
    }
}
</style>