
import Player from 'xgplayer'
import Loading from '../assets/loading.svg'
import '../style/controls/loading.scss'

let s_loading = function () {
  let player = this
  let root = player.root
  let util = Player.util
  let barStr = ''
  for(let i = 1; i <= 12; i++) {
    barStr += `<div class="xgplayer-enter-bar${i}"></div>`
  }
  let container = util.createDom('xg-loading', `<div class="xgplayer-enter-spinner">
</div>`, {}, 'xgplayer-loading')
  player.once('ready', () => {
    root.appendChild(container)
  })
}


// import Player from 'xgplayer'
// import '../style/controls/loading.scss'

// let s_loading = function () {
//   let player = this
//   let root = player.root
//   let util = Player.util

//   let barStr = ''
//   for(let i = 1; i <= 4; i++) {
//     barStr += `<div class="xgplayer-enter-bar${i}"></div>`
//   }
//   let enter = util.createDom('xg-enter', `<span class="xgplayer-enter-spinner">
//                                                   ${barStr}
//                                                 </span>`, {}, 'xgplayer-enter');
//   root.appendChild(enter)
// }


export default {
  name: 's_loading',
  method: s_loading
}