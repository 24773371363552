import Vue from "vue";
import VueRouter from "vue-router";
import Home from "../views/HomePage.vue";
// import pcHome from '../views/pcpage/pcHome'
import Wechat from "../components/mac/wechat.vue";
import Mac from '../views/MacPC/MacOS.vue'
Vue.use(VueRouter);

const originalPush = VueRouter.prototype.push;
const originaReplace = VueRouter.prototype.replace;
// push
VueRouter.prototype.push = function push(location, onResolve, onReject){
  if(onResolve || onReject) return originalPush.call(this, location, onResolve, onReject)
  return originalPush.call(this, location).catch(err => err)
}
// replace
VueRouter.prototype.replace = function replace(location, onResolve, onReject){
  if(onResolve || onReject) return originaReplace.call(this, location, onResolve, onReject)
  return originaReplace.call(this, location).catch(err => err)
}
let is_mobile = navigator.userAgent.match(
  /(phone|pod|iPhone|iPod|Android|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|Symbian|Windows Phone)/i
);
if (is_mobile) { // 移动端宽度大于500，显示pc端
  is_mobile = true;
}else{
  is_mobile = false;
}
const routes = [
  // 签名验证
  {
    path: "/signature",
    name: "Signature",
    component: () => import("../views/signature.vue"),
  },
  {
    path: "/event/:site_url",
    name: "Home",
    // component:is_mobile?Home:Mac,
    component:Home,
  },
  // pc微信登录验证
  {
    path: "/auth/oauth/state",
    name: "Wechat",
    component: Wechat,
  },
  {
    path: "/photo_gallery",
    name: "PhotoGallery",
    component: () => import("../views/PhotoGallery.vue"),
  },
  {
    path: "/schedule",
    name: "Schedule",
    component: () => import("../views/Schedule/index.vue"),
    // meta: {
    //   keepAlive: true //此页面需要缓存
    // }
  },
  {
    path: "/schedule_info",
    name: "scheduleInfo",
    component: () => import("../views/Schedule/newdetail.vue"),
  },
  {
    path: "/schedule_info3",
    name: "scheduleInfo3",
    component: () => import("../views/Schedule/gridDetail.vue"),
  },
  {
    path: "/cipher",
    name: "cipher",
    component: () => import("../views/Login/cipherPage.vue"),
  },
  {
    path: "/attend",
    name: "attend",
    component: () => import("../views/attend/index.vue"),
  },
  {
    path: "/attend_intro",
    name: "attendIntro",
    component: () => import("../views/attend/details.vue"),
  },
  {
    path: "/taskdetail",
    name: "taskdetail",
    component: () => import("../views/attend/taskdetail.vue"),
  },
  {
    path: "/article_list",
    name: "Articlelist",
    component: () => import('../views/Article/Articlelist.vue')
  },
  {
    path: "/article_details",
    name: "articleDetails",
    component: () => import("../views/Article/articleDetails.vue"),
  },
  {
    path: "/exhibition",
    name: "Exhibition",
    component: () => import("../views/Exhibition.vue"),//云展厅
  },
  {
    path: "/video",
    name: "Video",
    component: () => import("../views/video/video.vue"),
  },
  {
    path: "/fil_edownload",
    name: "Filedownload",
    component: () => import("../views/Filedownload.vue"),
  },
  {
    path: "/login",
    name: "Loginindex",
    component: () => import("../views/Login/Loginindex.vue"),
  },
  {
    path: "/login_information",
    name: "Logininformation",
    component: () => import("../views/Login/information.vue"),
  },
  {
    path: "/login_modify",
    name: "Loginmodify",
    component: () => import("../views/Login/modify.vue"),
  },
  {
    path: "/live_list",
    name: "liveList",
    component: () => import("../views/liveList.vue"),
  },
  // 系列会议
  {
    path: "/meeting",
    name: "meeting",
    component: () => import("../views/meeting/meeting.vue"),
  },
  // 会议详情
  {
    path: "/meeting_info",
    name: "MeetingInfo",
    component: () => import("../views/meeting/meeting_info.vue"),
  },
  {
    path: "/blank",
    name: "blank",
    component: () => import("../views/blank.vue"),
  },
  {
    path: "/cooperation",
    name: "cooperation",
    component: () => import("../views/cooperation.vue"),
  },
  {
    path: "/coopimg",
    name: "coopimg",
    component: () => import("../views/coopimg.vue"),
  },
  {
    path: "/userinfo",
    name: "userinfo",
    component: () => import("../views/userInfo/info.vue"),
  },
  {
    path: "/Collection",
    name: "Collection",
    component: () => import("../views/customer/Collection.vue"),
  },
  {
    path: "/history",
    name: "history",
    component: () => import("../views/customer/history.vue"),
  },
  {
    path: "/follow",
    name: "follow",
    component: () => import("../views/customer/follow.vue"),
  },
  {
    path: "/hotel",
    name: "hotel",
    component: () => import("../views/hotel/index.vue"),
  },
  {
    path: "/successful",
    name: "successful",
    component: () => import("../views/hotel/successful.vue"),
  },{
    path: "/antiepidemic",
    name: "antiepidemic",
    component: () => import("../views/hotel/antiepidemic.vue"),
  },
  {
    path: "/RegPayment",
    name: "RegPayment",
    component: () => import("../views/RegPayment.vue"),
  },
  {
    path: "/Addendum",
    name: "Addendum",
    component: () => import("../views/Addendum.vue"),
  },
  {
    path: "/ConferenceAddress",
    name: "ConferenceAddress",
    component: () => import("../views/ConferenceAddress.vue"),
  },
  {
    path: "/invoice",
    name: "invoice",
    component: () => import("../views/invoice.vue"),
  },
  {
    path: "/stationnull",
    name: "stationnull",
    component: () => import("../views/stationNull.vue"),
  },
  {
    path: "/:catchAll(.*)",
    redirect: "/stationnull",
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});



export default router;
