<template>
    <!-- logo墙 -->
    <div class="cooperation">
        <div class="coopBox" id="scroll">
            <div style="min-height: 95%;">
                <div class="coopCon">
                    <van-loading :color="themecolors" :text-color="themecolors" v-if="Loading" />
                    <van-empty :image="require('@/assets/null.svg')" v-else-if="imgdata.length == 0" />
                    <div v-else class="content_item" v-for="(item,index) in imgdata" :key="index">
                        <div class="img-center" @click="linkClick(item)">
                            <img v-lazy="item.partner_image_url" alt="">
                        </div>
                    </div>
                    <div class="content_item" style="box-shadow:none;background:none;"
                        v-if="this.imgdata.length%2 == 1"></div>
                </div>
            </div>
            <Support></Support>
        </div>
    </div>
</template>
<script>
import Support from '@/components/support';
import { mapState } from 'vuex'
export default {
    components: {
        Support
    },
    data() {
        return {
            Loading: false,
            imgdata: [],
            page_style: {},//背景颜色
            lightpage: 1,//默认显示第一条数据
            islastpage: false,//判断是不是最后一页数据
            flushbonading: true,
            show_grouping: '',
        }
    },
    props: {
        app: Object,
    },
    created() {
        let obj = {
            module_id: this.app.random_number,
            page_id: this.app.page_id,
            site_id: this.site_id,
        }
        this.params = obj;
        this.page_style = window.sessionStorage.getItem('background_setting')
    },
    mounted() {
        if (!this.app.exhibition_skip) {
            this.show_partner_info()
        } else {
            this.showroom_logo_wall_list()
        }
    },
    computed: {
        ...mapState(['themecolors', 'site_id'])
    },
    methods: {
        linkClick(item) {
            if (item.partner_link) {
                window.open(item.partner_link) 
            }
        },
        // 展厅展示logo墙分类列表
        showroom_logo_wall_list() {
            this.$store.dispatch("showroom_logo_wall_list", {
                site_id: this.site_id,
                show_grouping: this.app.show_grouping,
            }).then(res => {
                if (res.data.code === 200) {
                    this.imgdata = res.data.data.module_content
                }
            })
        },
        show_partner_info() {
            this.Loading = true;
            this.$store.dispatch("show_partner_info", {
                site_id: this.site_id,
                module_id: this.params.module_id,
            }).then(res => {
                this.Loading = false;
                if (res.data.code == 200) {
                    this.imgdata = res.data.data.module_content;
                }
            })
        },
    },
}
</script>
<style scoped lang="less">
/deep/.van-empty,
/deep/.van-loading {
    position: absolute;
    top: 30%;
    left: 50%;
    transform: translateX(-50%);
}

/deep/.van-empty {
    width: 100%;

    .van-empty__image {
        width: 300px;
        height: 300px;
    }
}

/deep/.van-loading {
    top: 50%;
}

.cooperation {
    max-width: 420px;
    background-size: 100% auto !important;
    background: no-repeat;
    box-sizing: border-box;
}

.coopBox {
    width: 100%;
    height: 100%;
    overflow-y: scroll;
    background: #fff;
    .coopCon {
        width: 88%;
        justify-content: space-around;
        // justify-content: center;
        margin: 0 auto;
        display: flex;
        flex-wrap: wrap;
        padding: 15px 0;

        .content_item {
            width: 44%;
            height: 75px;
            background: rgba(255, 255, 255, 0.9);
            // margin:0 0 8px 8px;
            margin-top: 12px;
            border-radius: 10px;
            position: relative;
            box-shadow: 0px 0px 2px 1px rgba(0, 0, 0, 0.05), 0px 0px 2px 1px rgba(0, 0, 0, 0.05), 2px 0px 2px 1px rgba(0, 0, 0, 0.05), 0px 2px 2px 1px rgba(0, 0, 0, 0.05);

            .img-center {
                position: absolute;
                width: 100%;
                height: 75px;
                display: flex;
                justify-content: space-around;
                align-items: center;

                img {
                    height: 45px;
                    max-width: 90%;
                }
            }
        }
    }
}
</style>