<template>
  <div>
    <div class="map-warp" :class="{'map_box':show}" id="scroll">
      <baidu-map @ready="loadedMap" :style="{height:show?'60%':'100%', width: '100%'}" :zoom="zoom"
        :center="pointMarker" :scroll-wheel-zoom="true" :dragging="true" :mapClick="false">
        <bm-navigation anchor="BMAP_ANCHOR_TOP_LEFT" type="BMAP_NAVIGATION_CONTROL_SMALL"></bm-navigation>
        <bm-map-type :map-types="maptypes" anchor="BMAP_ANCHOR_TOP_RIGHT"></bm-map-type>
        <!-- 交通流量图 -->
        <bm-traffic :predictDate="{weekday: 7, hour: 12}"></bm-traffic>
        <!-- 点 -->
        <bm-marker :position="pointMarker" :dragging="true" animation="BMAP_ANIMATION_BOUNCE">
          <bm-label :content="labelContent"
            :labelStyle="{color: '#fff', fontSize : '12px',backgroundColor:themecolor,border:'1px solid '+themecolors+'',padding:'0.50px',transform: 'translateX(-45%)'}"
            :offset="{ height: 30}" />
        </bm-marker>
        <van-button color="#fff" @click="popupShow" :style="{bottom:show?'40%':'0'}">
          <van-icon :class="{'van-animation':show,'van-end':!show}" name="arrow-up" color="#797877" />
        </van-button>
        <div class="popup_box">
          <div class="popup_top" :style="{background:themecolor}">
            路线图
          </div>
          <div class="popup_center">
            <div v-for="(item,index) in endlist" :key="index">
              <div class="location_box">
                <div class="location_info">
                  <div class="location_text" :style="{color:index==clickindex?themecolor:''}">{{item.departure_name}}
                  </div>
                  <div class="loction_distance">距离:{{item.distance||"0公里"}}</div>
                </div>
                <div class="loction_retrieval">
                  <div class="loction_Route" @click="DrivingShow(item,index,1)">打车路线</div>
                  <div class="loction_Route" @click="TransitShow(index,2)">公交路线</div>
                </div>
              </div>
              <bm-transit ref="transit" class="transit_box" v-show="transitShow" v-if="routetype==2&&clickiInde==index"
                :start="start" :end="end" :auto-viewport="true" policy="BMAP_TRANSIT_POLICY_LEAST_TIME" location="北京">
              </bm-transit>
              <bm-driving v-show="transitShow" v-if="routetype==1&&clickiInde==index" :start="start" :end="end"
                startCity="北京" endCity="北京" :auto-viewport="true" @markersset='markersset'></bm-driving>
            </div>
            <div v-if="endlist.length==0" class="popup_title">
              暂未设置路线
            </div>
          </div>
        </div>
      </baidu-map>
    </div>
  </div>
</template>
<script>
import { mapState } from "vuex";
export default {
  data() {
    return {
      // 地图
      map: "",
      // 地图中心点
      mapCenter: "",
      // 地图缩放
      zoom: 15,
      // 点
      point: {},
      // 详细地址
      address: "",
      // 点信息
      pointInfo: {},
      // 是否展示点信息
      showInfo: false,
      // 标注点的坐标信息
      pointMarker: { lat: 39.914853, lng: 116.404045 },
      // 地图坐标解析器
      gc: "",
      // 检索地址
      place: "",
      // 当前行政区划
      region: "北京",
      show: true,
      start: { lat: 39.914853, lng: 116.404045 },
      end: { lat: 39.914853, lng: 116.454045 },
      startCity: '',//开始城市
      endCity: '',//结束城市
      routetype: 0,//路线类型
      keyword: "",
      maptypes: ['BMAP_NORMAL_MAP', 'BMAP_HYBRID_MAP', 'BMAP_SATELLITE_MAP'],
      labelContent: "故宫",
      endlist: [],// 终点列表
      clickiInde: null,//点击第几个
      chicknumb: null,//防止重复点击
      transitShow: true,//路线显示隐藏
      clickindex: null,//点击的是哪一个路线
    };
  },
  computed: {
    ...mapState(["themecolors", "themecolor", 'site_id']),
  },
  created() {

  },
  mounted() {
    this.get_front_end_meeting_address()
  },
  methods: {
    get_front_end_meeting_address() {
      this.$store.dispatch("get_front_end_meeting_address", {
        site_id: this.site_id,
      }).then(res => {
        if (res.data.code == 200) {
          if (res.data.data.length != 0) {
            this.end.lat = res.data.data.destination.y
            this.end.lng = res.data.data.destination.x
            this.pointMarker.lat = res.data.data.destination.y
            this.pointMarker.lng = res.data.data.destination.x
            this.labelContent = res.data.data.destination.destination_name
            this.endlist = [...{ departure_name: "我的位置", x: '', y: '', }, ...res.data.data.departure]
            this.endlist.forEach((element, index) => {
              if (element.x) {
                element.distance = ""
                element.duration = ""
                this.directionlite(element.x, element.y, this.end.lng, this.end.lat, index)
              }
            });
            let geolocation = new BMap.Geolocation();
            geolocation.getCurrentPosition(e => {
              this.endlist[0].x = e.longitude
              this.endlist[0].y = e.latitude
              this.directionlite(this.endlist[0].x, this.endlist[0].y, this.end.lng, this.end.lat, 0)
            })
          }

        }
      })
    },
    directionlite(startlng, startlat, endlng, endlat, index) {
      var start = new BMap.Point(startlng, startlat);
      var end = new BMap.Point(endlng, endlat);
      this.endlist[index].distance = (this.map.getDistance(start, end) / 1000).toFixed(2) + "公里";
      this.$forceUpdate()
    },
    markersset(container) {
      this.getPointInfo(container[0].point, '0')
      this.getPointInfo(container[1].point, '1')
    },
    TransitShow(index, num) {
      if (this.chicknumb != num + '_' + index) {
        this.clickindex = index
        this.chicknumb = num + '_' + index
        this.clickiInde = index
        this.routetype = 2
        this.start.lng = this.endlist[index].x
        this.start.lat = this.endlist[index].y
        $(".BMapLabel,.BMap_Marker").hide()
        this.transitShow = true
      } else {
        this.transitShow = !this.transitShow
      }
    },
    DrivingShow(data, index, num) {
      if (this.chicknumb != num + '_' + index) {
        this.clickindex = index
        this.chicknumb = num + '_' + index
        this.clickiInde = index
        this.routetype = 1
        this.start.lng = this.endlist[index].x
        this.start.lat = this.endlist[index].y
        $(".BMapLabel,.BMap_Marker").hide()
        this.transitShow = true
      } else {
        this.transitShow = !this.transitShow
      }
    },
    popupShow() {
      this.show = !this.show
    },
    // 初始化地图
    loadedMap({ BMap, map }) {
      //创建地址解析器实例
      this.gc = new BMap.Geocoder();
      this.map = map;
      if (this.point.lat && this.point.lng) {
        this.mapCenter = this.point;
        this.addMarker(this.point);
        this.pointInfo.location = this.point;
      } else {
        this.mapCenter = "北京";
      }
    },
    // 获取点信息
    getPointInfo(point, place) {
      this.pointInfo.address = "";
      let that = this;
      // 解析坐标点--获取坐标点所在的区域名称（城市）
      that.gc.getLocation(point, function (res) {
        let detailAddress = res.addressComponents;
        if (place == 0) {
          that.startCity = detailAddress.city
        } else if (place == 1) {
          that.endCity = detailAddress.city
        } else {
          console.log(res);
        }
      });
    },
    // 添加标注——跳动的点
    addMarker(point) {
      this.pointMarker = point;
    },
  },
};
</script>
<style scoped lang="scss">
.map_box {
  overflow-y: scroll !important;
}

.map-warp {
  position: relative;
  height: 100%;
  background: #fff;

  .popup_box {
    min-height: 65%;
    // background: #fff;
  }

  .popup_top {
    width: 100%;
    height: 50px;
    line-height: 50px;
    font-size: 16px;
    color: #fff;
    text-align: center;
  }

  .popup_center {
    height: calc(100% - 50px);
    overflow-y: auto;
    padding: 0 10px;
    .popup_title{
      font-size: 16px;
      text-align: center;
      margin-top: 40px
    }
    .location_box {
      padding: 10px 0;
      text-align: left;
      border-bottom: 1px solid #999;
      display: flex;
      align-items: center;

      .location_icon {
        font-size: 28px;
        margin-right: 10px;
      }

      .location_info {
        flex: 1;
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        margin-right: 10px;

        .location_text {
          font-size: 14px;
          margin-bottom: 10px;
          display: -webkit-box;
          overflow: hidden;
          word-break: break-all;
          width: 100%;
          -webkit-box-orient: vertical;
          -webkit-line-clamp: 2;
        }

        .loction_distance {
          font-size: 12px;
        }
      }
    }

    .loction_retrieval {
      .loction_Route {
        padding: 5px 10px;
        font-size: 12px;
        color: #63605e;
        border: solid 0.10px #909090;
        border-radius: 30px;

        &:first-child {
          margin-bottom: 010px;
        }
      }
    }

  }

  .van-button {
    width:100px;
    position: absolute;
    bottom: 0;
    left: 50%;
    transform: translateX(-50%);

    .van-icon {
      font-size: 24px;
      font-weight: bold;
    }
  }

  .van-animation {
    animation: mymove 0.3s 1 forwards;
  }

  .van-end {
    animation: end 0.3s 1 forwards;
  }

  @keyframes mymove {
    0% {
      transform: rotate(0deg);
    }

    100% {
      transform: rotate(180deg);
    }
  }

  @keyframes end {
    0% {
      transform: rotate(180deg);
    }

    100% {
      transform: rotate(0deg);
    }
  }
}
</style>
<style lang="scss">
.my-autocomplete {
  li {
    line-height: normal;
    padding: 7px;

    .name {
      text-overflow: ellipsis;
      overflow: hidden;
    }

    .addr {
      font-size: 12px;
      color: #b4b4b4;
    }

    .highlighted .addr {
      color: #ddd;
    }
  }
}
</style>