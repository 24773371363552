<template>
<!-- 防疫码 -->
    <loading v-if="isHttpData" style="margin-top: 210px"></loading>
    <div v-else class="antiepidemic_box">
        <div class="antiepidemic" v-if="isNull" id="scroll" :style="{'--themecolor':themecolors,'--rgbathemecolor':themecolor}">
            <div style="min-height:95%">
                <div class="main">
                    <div class="flex between">
                        <div class="top content-left" >
                            <p v-html="desc"></p>
                        </div>
                        <div class="content-right">
                            <img src="@/assets/icon/safety.svg" class="safety" alt="">
                            <i class="el-icon-arrow-right" v-if="showArrow" @click="unfold"></i>
                        </div>
                    </div>
                    <div class="code-box">
                        <div class="code-group">
                            <el-upload
                            class="upload-demo"
                            action="/api/v1/micro/qiniu/upload_images"
                            :before-upload="beforeUpload"
                            :on-success="handleAvatarSuccess"
                            :data="{storage_space: '1'}"
                            :show-file-list="false"
                            >
                                <el-button size="small" type="primary">{{ button_name }}</el-button>
                            </el-upload>
                            <p v-if="!img_data.url">示例图片</p>
                            <div class="img-box" :style="isImg?'':'margin-top: 30px;'">
                                <van-loading :color="themecolors" style="margin-top:80px;" v-if="loading"/>
                                <img :src="img_data.url" alt="" v-lazy="img_data.url" @click="imagePreview(img_data.url)" v-else>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
             <Support v-if="isNull" :style="!isNull ? 'position:absolute;bottom: 60px;left: 50%; transform: translate(-50%, 0);' : ''"></Support>
        </div>
        <img v-show="!isNull" class="position-img" src="@/assets/null.svg" alt="">
    </div>
</template>

<script>
import { mapState } from 'vuex'
import Support from '@/components/support';
import { ImagePreview } from "vant"; // 引入Vant图片预览组件
import Loading from "@/components/loading/loading";
export default {
    computed: {
        ...mapState(['themecolors','themecolor',"site_id"])
    },
    components:{
        Support,
        Loading
    },
    props:{
        app:Object
    },
    data(){
        return{
            img_data:{
                url:"",
                id:"",
            },
            loading:false,
            desc:"",
            button_name:"",
            isHttpData: true,
            isNull: false,
            showArrow: true,
            isImg: false,
            file_name:'',
            journey_name:'',
            journey_img_name:'',
            journey_img_id:'',
            journey_img_url:''
            
        }
    },
    created(){
        this.check_epidemic_prevention_code()
    },
    methods:{
        unfold(){
            this.$nextTick().then(() => {
                var minHeight = document.querySelector('.content-left').clientHeight
                var pHeight = document.querySelector('.content-left p').clientHeight
                var arrow = document.querySelector('.content-right .el-icon-arrow-right')
                if(pHeight > 80){
                    if(minHeight>80){
                        document.querySelector('.content-left').style.height = '80px'
                        arrow.classList.remove('is-rotate')
                    }else{
                        document.querySelector('.content-left').style.height = pHeight + 10 + 'px'
                        arrow.classList.add('is-rotate')
                    }
                }
            })
        },
        imagePreview(image){
            let img = [];
            img.push(image)
            ImagePreview({
                images:img, //需要预览的图片 URL 数组
                showIndex:false, //是否显示页码
                loop:false, //是否开启循环播放
                closeable: true,  // 显示关闭按钮
            });
        },
        // 用户查看防疫码
        async check_epidemic_prevention_code(){
            const res = await this.$store.dispatch('check_epidemic_prevention_code',{
                site_id:this.site_id,
                module_id: this.app.random_number
            });
            if(res.data.code === 200) {
                this.isHttpData = false
                this.isNull = true
                if(res.data.data.module_content.image_id_url){
                    this.isImg = false
                    this.desc = res.data.data.module_content.describe;
                    this.img_data.url = res.data.data.module_content.image_id_url;
                    this.img_data.id = res.data.data.module_content.image_id;
                    this.button_name = res.data.data.module_content.button_name;
                    document.title = res.data.data.module_title ? res.data.data.module_title : (sessionStorage.getItem('wei-title') || '轻微站');
                    this.$nextTick().then(() => {
                        var minHeight = document.querySelector('.content-left p').clientHeight
                        if(minHeight > 80){
                            this.showArrow = true
                        }else{
                            this.showArrow = false
                        }
                    })
                }else{
                    this.isImg = true
                    this.get_epidemic_prevention_code()
                }
            }else{
                this.isNull = false
                this.isHttpData = false
            }
        },
        // 前台展示防疫码
        async get_epidemic_prevention_code(){
            const res = await this.$store.dispatch('get_epidemic_prevention_code',{site_id:this.site_id});
            if(res.data.code === 200) {
                this.desc = res.data.data.describe;
                this.img_data.url = res.data.data.image_id_url;
                this.img_data.id = res.data.data.image_id;
                this.button_name = res.data.data.button_name;
            }else{
                this.$toast(res.data.message)
            }
        },
        beforeUpload(file){
            const limittypearr = ['jpg', 'gif', 'png', 'jpeg', 'webp']
            const isLt2M = file.size / 1024 / 1024 / 1024 / 1024 / 1024 < 2
            const limittye = file.type.split('/')[1]
            if (!isLt2M) {
              this.$message.error('上传防疫码图片大小不能超过 2MB!')
              return false
            }
            if (!limittypearr.includes(limittye)) {
              this.$message.error('上传防疫码图片格式不正确！')
              return false
            }
            this.file_name = file.name
            return true
        },
        handleAvatarSuccess(res){
            if(res.code == 200){
                this.$toast('上传成功')
            }else{
                this.$toast('上传失败')
            }
            this.img_data.id = res.data[this.file_name].id
            this.img_data.url = res.data[this.file_name].url
            this.user_add_epidemic_prevention_code()
        },
        async changeImg(e){
            const limittypearr = ['jpg', 'png', 'jpeg']
            const limittye = e.target.files[0].type.split('/')[1]
            if (!limittypearr.includes(limittye)) {
                this.$toast('上传图片格式不正确！')
                e.target.value = ''
                return false
            }
            if(e.target.files[0].size/1024>2048 ){
                this.$toast("上传图片不能大于2M！")
                e.target.value = ''
                return;
            }
            let files = e.target.files[0]
            let formData = new FormData()
            formData.append('files',files)
            formData.append("storage_space",'1')
            let file_name = files.name;
            this.loading = true;
            const res = await this.$store.dispatch('upload_images',formData)
            if(res) {
                e.target.value = '';
                this.loading = false;
            }
            if(res.data.code === 200) {
                this.img_data.url = res.data.data[file_name].url
                this.img_data.id = res.data.data[file_name].id
                this.user_add_epidemic_prevention_code()
            }
        },
        // 用户上传防疫码
        async user_add_epidemic_prevention_code(){
            let params = {
                site_id:this.site_id,
                image_id:this.img_data.id,
            }
            const res = await this.$store.dispatch('user_add_epidemic_prevention_code',params);
        }
    }
}
</script>

<style lang='less' scoped>
@Color:var(--themecolor);
@rgbathemecolor:var(--rgbathemecolor);
.position-img{
    position: fixed;
    width: 300px;
    height: 300px;
    left: 50%;
    top: 45%;
    transform: translate(-50%, -50%);
}
.flex{
    display: flex;
}
.between{
    justify-content: space-between;
}
.is-rotate{
    -ms-transform: rotate(90deg); /* IE 9 */
    transform: rotate(90deg);
}
.antiepidemic_box{
    background: #fff;
    max-width: 420px;
}
.antiepidemic{
    padding: 20px 20px 0 20px; 
    background: #F3F2F7;
    height: 100%;
    overflow: auto;
    .main{
        min-height: 400px;
        background: linear-gradient(270deg, #70D6FE 0%, #00F5D3 100%);
        border-radius: 10px;
        padding: 15px;
        line-height: 25px;
        .content-left{
            flex: 1;
            padding: 4px 6px;
            box-sizing: border-box;
            min-height: 60px;
            height: 80px;
            overflow: hidden;
            transition: height 1s;
            -moz-transition: height 1s;
            -webkit-transition: height 1s;
            -o-transition: height 1s;
        }
        .content-right{
            display: flex;
            flex-direction: column;
            justify-content: flex-start;
            align-items: center;
            .el-icon-arrow-right{
                font-size: 24px;
                margin-top: 4px;
                z-index: 20;
                transform: 1s;
            }
        }
        h2{
            font-size: 16px;
        }
        p{
            font-size: 13px;
        }
        .top{
            width: 80%;
            font-size: .20px;
        }
        .safety{
            width: 45px;
        }
        .code-box{
            min-height:340px;
            background: #FFFFFF;
            border-radius: 10px;
            margin-top: 15px;
            padding: 20px;
            text-align: center;
            .mar-35{
                margin-top: 35px;
            }
            /deep/.upload-demo{
                width: 123px!important;
                height: 40px!important;
                position: relative;
                left: 50%;
                transform: translateX(-50%);
                .el-upload.el-upload--text{
                    width: 123px!important;
                    height: 40px!important;
                    position: absolute;
                    left: 0;
                    top: 0;
                    .el-button--primary{
                        width: 123px!important;
                        height: 40px!important;
                        position: absolute;
                        left: 0;
                        top: 0;
                        background-color: #00CCB0;
                        border: none;
                        border-radius: 20px;
                    }
                }
            }
            .btn{
                width: 110px;
                height: 40px;
                background: @Color;
                border-radius: 25px;
                font-size: 14px;
                color: #fff;
                border: none;
                text-align: center;
                overflow: hidden;
                margin: 0 auto;
                position: relative;
                input,button{
                    position: absolute;
                    left: 0;
                    bottom: 0;
                    width: 110px;
                    height: 40px;
                    background: @Color;
                    border-radius: 25px;
                    font-size: 14px;
                    color: #fff;
                    border: none;
                    text-align: center;
                }
                input{
                    z-index: 2;
                }
                
            }
            p{
                margin-top: 15px;
            }
            .img-box{
                width: 135px;
                height:200px;
                border: 2px dashed #C9C9C9;
                border-radius: 10px;
                margin: 15px auto 0;
                overflow: hidden;
                img{
                    width: 100%;
                    height: 100%;
                    // object-fit: cover;
                    object-fit: scale-down;
                }
            }
        }
    }
}
</style>