<template>
  <div class="launchpad">
    <div class="body" @click="openApp">
      <div class="swiper mySwiper">
        <div class="swiper-wrapper">
          <div class="swiper-slide" v-for="(i,index) in deskTopAppList" :key="index">
            <div class="launchpad-app ">
              <div v-for="item in i" :key="item.key">
                <div class="app-item" @click.stop="$store.commit('openApp', item)" v-if="item.keepInDock">
                  <div class="icon">
                    <div class="iconfont" :style="{
                      background: item.module_style.module_background_color.selected == 1&&item.module_style.module_background_switch==1 ? `url(${item.module_style.module_background_color.module_background_url})` : '',
                      backgroundColor: item.module_style.module_background_color.selected == 3 ? item.module_style.module_background_color.customize : '',
                    }">
                      <img v-if="item.module_style.icon" :src="item.module_style.icon" alt="" />
                    </div>
                    <div class="title">{{ item.module_title_content.module_title }}</div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="swiper-pagination" v-if="deskTopAppList.length>1"></div>
      </div>
    </div>
    <Dock></Dock>
  </div>
</template>

<script>
import Swiper from "swiper";
import Dock from "@/components/mac/Dock.vue";
export default {
  components: {
    Dock,
  },
  data() {
    return {
      deskTopAppList: [],
    };
  },
  watch: {
    deskTopAppList(newval) {
      if (newval.length > 0) {
        new Swiper(".mySwiper", {
          observer: true,
          observeParents: true,
          pagination: {
            el: ".swiper-pagination",
          },
        });
      }
    }

  },
  props: {
    moduleContent: Array,
  },
  created() {
    for (var i = 0, len = this.moduleContent.length; i < len; i += 35) {
      this.deskTopAppList.push(this.moduleContent.slice(i, i + 35));
    }
  },
  methods: {
    launchpad() {
      this.$emit("launchpad", this.$store.state.launchpad);
    },
    openApp() {
      this.$store.state.launchpad = false
    }
  },
};
</script>

<style lang="scss" scoped>
.launchpad {
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: white;
  // margin-top: -100px;
  z-index: 99900;
  backdrop-filter: blur(100px);

  .body {
    flex-grow: 1;
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    position: relative;
    background: rgba(0, 0, 0, .2);
    .swiper {
        width: 100%;
        height: 100%;
      }
    .swiper-slide {
      width: 100% !important;
    }
    .swiper-pagination{
      font-size: 14px;
      bottom: 80px;
      /deep/.swiper-pagination-bullet-active{
        background: #fff !important;
      }
    }
    .launchpad-app {
      width: 70vw;
      margin: 0 auto;
      display: grid;
      grid-template-columns: repeat(7, 1fr);

      .app-item {
        margin-top: 40px;
        flex-direction: column;
        text-align: center;
        text-shadow: 0px 0px 2px rgba(0, 0, 0, .5);
        cursor: pointer;
        border-radius: 10px;
        border: 2px solid transparent;
        justify-content: center;
        align-items: center;
        box-sizing: border-box;

        .icon {
          width: 100%;
          height: 100%;
          display: flex;
          justify-content: center;
          align-items: center;
          text-align: center;
          flex-direction: column;
        }

        img {
          width: auto;
          height: 30px;
        }

        .iconfont {
          border-radius: 20px;
          display: flex;
          justify-content: center;
          align-items: center;
          flex-direction: column;
          width: 100px;
          height: 100px;
          background-size: cover !important;

        }

        .title {
          font-size: 15px;
          margin-top: 5px;
          white-space: nowrap;
          text-overflow: ellipsis;
          overflow: hidden;
        }
      }
    }
  }

  .footer {
    position: absolute;
    display: flex;
    z-index: 100;
    bottom: 4px;
    // margin-bottom: 4px;
  }
}
</style>
